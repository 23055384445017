

export default class Template {
	public uid: string | null = null;
	public creationDate: string | null = null;
	public config: TemplateConfig = new TemplateConfig();

	constructor(obj: any = {}) {
		if (typeof obj === 'string') {
			obj = JSON.parse(obj);
		}
		Template.buildFromJson(this, obj);
	}

	private static buildFromJson(instance: any, json: any) {
		if (json) {
			for (let prop in json) {
				if (prop === 'config') {
					instance[prop] = new TemplateConfig(json[prop]);
				} else {
					instance[prop] = json[prop];
				}
			}
		}
	}

}

export class TemplateConfig {
	public availableForms: string[] = [];

	constructor(obj: any = {}) {
		if (typeof obj === 'string') {
			obj = JSON.parse(obj);
		}
		TemplateConfig.buildFromJson(this, obj);
	}

	private static buildFromJson(instance: any, json: any) {
		if (json) {
			for (let prop in json) {
				instance[prop] = json[prop];
			}
		}
	}
}