import Family from "./Family";


export default class FamilyEvent {
	public data: EventData = new EventData();
	public eventDate?: string;
	public families: string[] | null = [];
	public authorProfileUid?: string;
	public uid?: string;
	public templateFormUid: string | null = null;

	get isFullPage():boolean{
		if(this.templateFormUid===FamilyEvent.FORM_FULLPAGE_CONTAIN_UID){
			return true;
		}

		return false;
	}

	public creationDate?: string;

	public static FORM_PORTRAIT_UID = '28cea1bf-6eaf-4c29-b669-87491fe4492f';
	public static FORM_PORTRAIT_CONTAIN_UID = '29cea1bf-6eaf-4c29-b669-87491fe4492f';
	public static FORM_LANDSCAPE_UID = '6ab5088d-832b-42d1-8cef-0bb40928254a';
	public static FORM_LANDSCAPE_CONTAIN_UID = '7ab5088d-832b-42d1-8cef-0bb40928254a';
	public static FORM_FULLPAGE_CONTAIN_UID = 'fcce3dd3-e078-d965-32c2-2890fe84b1a7';

	constructor(obj: any = {}) {
		if (typeof obj === 'string') {
			obj = JSON.parse(obj);
		}
		FamilyEvent.buildFromJson(this, obj);
	}

	private static buildFromJson(instance: any, json: any) {
		if (json) {
			for (let prop in json) {
				if (prop === 'data') {
					instance[prop] = new EventData(json[prop]);
				} else {
					instance[prop] = json[prop];
				}
			}
		}
	}

	exportToJson(preview: boolean = false) {
		let JSONExport: any = {};
		if (this.uid) JSONExport.uid = this.uid;
		if (this.authorProfileUid) JSONExport.authorProfileUid = this.authorProfileUid;
		JSONExport.data = this.data.exportToJson(preview);
		if (this.families && this.families.length > 0) {
			JSONExport.families = [];
			for (let e of this.families) {
				JSONExport.families.push(e);
			}
		}

		if (this.eventDate) {
			JSONExport.eventDate = this.eventDate;
		}

		JSONExport.templateFormUid = this.templateFormUid;
		return JSONExport;
	}

	addFamily(family: Family): boolean {
		let isAlreadyExiste: boolean = false;
		if (this.families) {
			for (let f of this.families) {
				if (f === family.uid) {
					isAlreadyExiste = true;
					break;
				}
			}
		} else {
			this.families = [];
		}
		if (!isAlreadyExiste && family.uid && this.families) {
			this.families.push(family.uid);
		}
		return isAlreadyExiste;
	}

	addUploadCrop(data: { x1: number, y1: number, x2: number, y2: number }) {
		this.data.uploadCrop = new UploadCrop(data);
	}

}


export class EventData {

	public message: string = "";
	public location: string = "";
	public uploadBase64: string = "";
	public upload?: string;
	public uploadUrl?: string;

	public uploadAlternates: UploadAlternate[] = [];

	public uploadCrop: UploadCrop | null = null;

	get_image() {
		for (let u of this.uploadAlternates) {
			if (u.type === "72") {
				return u.url;
			}
		}
		for (let u of this.uploadAlternates) {
			if (u.type === "144") {
				return u.url;
			}
		}
		for (let u of this.uploadAlternates) {
			if (u.type === "crop") {
				return u.url;
			}
		}
		return this.uploadUrl;
	}

	constructor(obj: any = {}) {
		if (typeof obj === 'string') {
			obj = JSON.parse(obj);
		}
		EventData.buildFromJson(this, obj);
	}

	private static buildFromJson(instance: any, json: any) {
		if (json) {
			for (let prop in json) {
				if (prop === 'uploadAlternates') {
					if (json[prop]) {
						for (let subProp in json[prop]) {
							instance.uploadAlternates.push(new UploadAlternate(json[prop][subProp]));
						}
					}
				} else if (prop === 'uploadCrop') {
					if (json[prop]) {
						instance.uploadCrop = new UploadCrop(json[prop]);
					}
				} else if (prop === 'message') {
					if (json[prop]) {
						instance.message = EventData.htmlDecode(json[prop]);
					}
				} else {
					instance[prop] = json[prop];
				}
			}

		}
	}

	exportToJson(preview: boolean = false) {
		let JSONExport: any = {};
		JSONExport.message = this.message;
		if (this.location !== null && this.location !== undefined && this.location !== "") {
			JSONExport.location = this.location;
		}


		if (preview) {
			JSONExport.uploadBase64 = this.get_image();
		} else {
			if (this.uploadBase64 !== "") {
				JSONExport.uploadBase64 = this.uploadBase64;
			}
			if (this.upload !== "") {
				JSONExport.upload = this.upload;
			}
			if (this.uploadCrop) {
				JSONExport.uploadCrop = this.uploadCrop.exportToJson();
			}
		}

		return JSONExport;
	}

	public static htmlDecode(value: string): string | null {
		let e = document.createElement('textarea');
		e.innerHTML = value;
		return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
	}

}

export class UploadAlternate {
	public name!: string;
	public type!: string;
	public url!: string;

	constructor(obj: any = {}) {
		if (typeof obj === 'string') {
			obj = JSON.parse(obj);
		}
		UploadAlternate.buildFromJson(this, obj);
	}

	private static buildFromJson(instance: any, json: any) {
		if (json) {
			for (let prop in json) {
				instance[prop] = json[prop];
			}
		}
	}

	exportToJson() {
	}
}

export class UploadCrop {
	public x1!: number;
	public y1!: number;
	public x2!: number;
	public y2!: number;

	constructor(obj: any = {}) {
		if (typeof obj === 'string') {
			obj = JSON.parse(obj);
		}
		UploadCrop.buildFromJson(this, obj);
	}

	private static buildFromJson(instance: any, json: any) {
		if (json) {
			for (let prop in json) {
				instance[prop] = json[prop];
			}
		}
	}

	exportToJson(): any {
		let JSONExport: any = {};
		JSONExport.x1 = this.x1;
		JSONExport.y1 = this.y1;
		JSONExport.x2 = this.x2;
		JSONExport.y2 = this.y2;
		return JSONExport;
	}
}

