import Repository from "./Repository";
import Characteristic from "gaia-sdk-js/lib/src/class/Identity/Characteristic";

export default class CharacteristicRepository extends Repository {

	constructor(config: { apiUrl: string, accessToken: string }) {
		super(config);
	}

	public update(profileOrUserUid: string, characteristic: Characteristic, options?: { isCompany?: boolean, isUser?: boolean, isProfile?: boolean }): Promise<any> {
		return this.request(this.apiUrl +
			(options ? (options.isCompany ? 'Companies' : (options.isUser ? 'Users' : 'Profiles')) : 'Profiles')
			+ '/' + profileOrUserUid + '/Characteristic/' + (characteristic.uid ? characteristic.uid + '/' : ''),
			characteristic.exportToJson(),
			(characteristic.uid ? 'PUT' : 'POST'),
			false,
			{headers: this.header}
		).then(function (JSON: any) {
			return JSON;
		}).catch(function (data: any) {
			return data;
		});
	}
}