import './assets/css/global.scss';
import './assets/css/bottom.scss';
import './assets/css/navbar.scss';
import './components/periods/period.scss';
import './components/invitation/invitation.scss';
import './components/members/members-component.scss';
import 'fomantic/src/semantic.css';
import "jquery";
import "fomantic/src/semantic.js";
import "./libs/PreviewImage/previewImage.min.js";
import "horizon-css/src/horizon.css";
import "font-awesome-sdk/src/css/pro-v4-font-face.min.css";
import "font-awesome-sdk/src/css/pro-v4-shims.min.css";
import "font-awesome-sdk/src/css/pro.min.css";
import Config from "./Config";
import {DependencyInjector, DependencyInjectorInstance} from "horizon-js-front-sdk/lib/DependencyInjector";
import {Logger} from "horizon-js-front-sdk/lib/Logger";
import BottomView from "./views/bottom";
import {Router} from "horizon-js-front-sdk/lib/Router/Router";
import id_ProfileRepository from "./libs/IdentityGatewayApiJs/repositories/ProfilRepository";
import Session from "./classes/Session";
import CharacteristicRepository from "./libs/IdentityGatewayApiJs/repositories/CharacteristicRepository";
import NavbarView from "./views/navbar";
import {SentryJs} from "./libs/SentryJs/src/sentry";
import ProfileRepository from "./repositories/ProfileRepository";
import * as Sentry from "@sentry/browser";
import {Severity} from "@sentry/browser";
import {Native} from "native-universal-container-bridge/lib/native";
import Profile from "gaia-sdk-js/lib/src/class/Identity/Profile";
import GtmManager from "./libs/GoogleTagManager/src/GTMManager";
import {SidebarView} from "./pages/SidebarView";

Logger.level = Logger.INFO;
let injector: DependencyInjector = DependencyInjectorInstance();

let config: Config = new Config();

function initApp() {
	injector.register(Config, config);

	//
	// Manage GTM: disable on native IOS app
	//
	let systemInfoNative: Native.SystemInfo = injector.getInstance(Native.SystemInfo);
	systemInfoNative.getDeviceInfo().then((deviceInfo) => {
		if(deviceInfo.deviceManufacturer !== 'Apple') {
			enableGtm();
		}
	}).catch(() => {
		// Non native
		enableGtm();
	});

	let waitingPromise: Promise<any>[] = [];
	if (config.sentryDsn) {
		SentryJs.init(config.sentryDsn, config.sentryEnvironment);
	}

	if (config.identityGateway_apiUrl) {
		if (Session.getOauthToken() !== '') {
			let profileRepository: id_ProfileRepository = new id_ProfileRepository({
				apiUrl: config.identityGateway_apiUrl,
				accessToken: Session.getOauthToken() ?? ''
			});
			injector.register(id_ProfileRepository, profileRepository);
			let characteristicRepository: CharacteristicRepository = new CharacteristicRepository({
				apiUrl: config.identityGateway_apiUrl,
				accessToken: Session.getOauthToken() ?? ''
			});
			injector.register(CharacteristicRepository, characteristicRepository);
			//Gestion de l'inscription anonyme si besoin.
			waitingPromise.push(manage_anonymous_sign_in());
		} else {
			// console.log("Session.getOauthToken() is not defined");
		}
	} else {
		console.error("identityGateway_apiUrl is not defined");
	}

	if(config.maintenance){
		let admin = localStorage.getItem('admin');
		console.log("admin :", admin);
		if(!admin){
			window.location.href = '/maintenance.html';
			return;
		}
	}

	if (window.location.pathname === '/index.html' || window.location.pathname === '/') {
		let oldRoute = Router.extractPageFromUrl({
			type: 'HASHBANG',
			config: {registerGlobalInterceptor: false, prefix: '#!'}
		});
		if (oldRoute !== 'index')
			window.location.href = '/' + oldRoute + '.html';
	}

	let router = new Router('/pages/', {
		type: 'URL',
		config: {
			suffixes: ['.html'],
			prefix: '/',
			registerGlobalInterceptor: true
		}
	}, 'page');

	Promise.all(waitingPromise).then(() => {
		init_routes();
	}).catch(() => {
		init_routes();
	});

	function init_routes() {
		router.addRoute({location: 'index', loader: () => import('./pages/index')});
		router.addRoute({location: 'upload', loader: () => import('./pages/upload')});
		router.addRoute({location: 'family', loader: () => import('./pages/family')});
		router.addRoute({location: 'credit', loader: () => import('./pages/credit')});
		router.addRoute({regex: new RegExp(/family\//.source + /.*/.source), loader: () => import('./pages/family')});

		router.addRoute({location: 'book', loader: () => import('./pages/book')});
		router.addRoute({location: 'auth', loader: () => import('./pages/auth')});
		router.addRoute({location: 'sign', loader: () => import('./pages/signin')});
		router.addRoute({location: 'admin', loader: () => import('./pages/admin')});
		router.addRoute({location: 'discovery', loader: () => import('./pages/discovery')});
		// router.addRoute({location: 'image', loader: () => import('./pages/image')});

		DependencyInjectorInstance().register(Router, router);
		router.updateCurrentPageFromCurrentLocation();

		if ((new URL(window.location.href).pathname) !== "/auth") {
			// Sidebar
			let sidebarView = new SidebarView('#sidebar');
			injector.register(SidebarView, sidebarView);

			// Navbar
			let navbar: NavbarView = new NavbarView('#navbar');
			injector.register(NavbarView, navbar);

			// Bottom
			let bottom: BottomView = new BottomView('#bottom');
			injector.register(BottomView, bottom);
		}
	}

	let loadingScreenNative: Native.LoadingScreen = DependencyInjectorInstance().getInstance(Native.LoadingScreen);
	loadingScreenNative.disable().catch((err)=>{

	});
}

if (!config.isValid()) {
	config.getFromServer().then(function (JSONConfig: any) {
		config = new Config(JSONConfig);
		// let xDomainParameter:any = {};
		//
		// let plDomain = config.pl_clientSideDomain;
		// if (plDomain){
		//     let nddWithoutSlashAtTheEnd:string='';
		//     if(plDomain.charAt(plDomain.length-1)==='/'){
		//         nddWithoutSlashAtTheEnd = plDomain.substring(0, plDomain.length - 1);
		//     }
		//     xDomainParameter[nddWithoutSlashAtTheEnd]="/api/cors";
		// }
		// xdomain.slaves(xDomainParameter);
		//
		// let storedVersion = window.localStorage.getItem('version');
		// if (storedVersion !== null) {
		//     if (storedVersion !== config.version) {
		//         window.location.reload(true);
		//     }
		// }
		initApp();
	}).catch(function (error) {
		console.log("Error : Index.ts " + error);
	});
} else {
	initApp();
}

function manage_anonymous_sign_in(): Promise<any> {
	let rep = new ProfileRepository();
	let owner = rep.get_take_ownership();
	if (owner && Session.getOauthToken() !== null) {
		return rep.take_owner_ship(owner).then((profile: Profile) => {
			rep.delete_take_ownership();
			rep.get();
		}).catch((xhr: any) => {
			if (xhr && xhr.status === 404) {
				Sentry.captureMessage("OWNERSHIP_ALREADY_TAKEN", (scope) => {
					scope.setLevel(Severity.Info);
					return scope;
				});
				rep.delete_take_ownership();
				if ((new URL(window.location.href).pathname) !== "/auth") {
					return rep.get();
				} else {
					return Promise.reject();
				}
			} else {
				Sentry.captureMessage("manage_anonymous_sign_in", (scope) => {
					scope.setLevel(Severity.Critical);
					return scope;
				});
				return Promise.reject();
			}
		});
	} else if (Session.getOauthToken() !== null) {
		if ((new URL(window.location.href).pathname) !== "/auth") {
			return rep.get();
		} else {
			return Promise.reject("Session.getOauthToken() !== null in auth so go to sign in.");
		}
	} else {
		return Promise.resolve();
	}
}

function enableGtm() {
	let gtmManager: GtmManager = injector.getInstance(GtmManager);
	if(config.gtmId) {
		gtmManager.init(config.gtmId);
	}
}