/** https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#add_payment_info **/

export enum GTM_Event {
	ADD_TO_WISHLIST = "add_to_wishlist",
	REMOVE_FROM_CART = "remove_from_cart",
	UPDATECART = "updateCart",
	PAGEVIEW = "pageview",
	SIGN_UP = "sign_up",
	TUTORIAL_BEGIN = "tutorial_begin",
	SHARE = "share",
	ADD_SHIPPING_INFO = "add_shipping_info",
	BEGIN_CHECKOUT = "begin_checkout",
	PURCHASE = "purchase",
}

export default class GoogleTagManagerData {

	public event!: GTM_Event;
	public ecommerce?: {
		value: number,//Prix total
		currency: 'EUR',
		items: { item_id: string, price: number, item_name: string, quantity: number }[],
	};
	public profile?: {
		email: string,
		firstname?: string,
		lastname?: string,
	}

	public method?:string;

	/** Getters **/
	get email(): string | null {
		if (!this.profile)
			return null;

		return this.profile.email;
	}

	get firstname(): string | null {
		if (!this.profile)
			return null;
		return this.profile.firstname ?? null;
	}

	get lastname(): string | null {
		if (!this.profile)
			return null;
		return this.profile.lastname ?? null;
	}

	constructor(obj: any = {}) {
		if (typeof obj === 'string') {
			obj = JSON.parse(obj);
		}
		GoogleTagManagerData.buildFromJson(this, obj);
	}

	private static buildFromJson(instance: any, json: any) {
		if (json) {
			for (let prop in json) {
				if (json.hasOwnProperty(prop)) {
					instance[prop] = json[prop];
				}
			}
		}
	}
}