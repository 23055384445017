import {EventData} from "./FamilyEvent";
import Family from "./Family";

export default class Subscription {
	public uid: string | null = null;
	public boughtPeriods: number = 1;
	public periodDuration: number = 4;
	public maxPages: number = 30;
	public basePrice: number = 590;
	public data: SubscriptionData = new SubscriptionData();

	constructor(obj: any = {}) {
		if (typeof obj === 'string') {
			obj = JSON.parse(obj);
		}
		Subscription.buildFromJson(this, obj);
	}
	private static buildFromJson(instance: any, json: any) {
		if (json) {
			for (let prop in json) {
				if (prop === 'data') {
					instance[prop] = new SubscriptionData(json[prop]);
				} else {
					instance[prop] = json[prop];
				}
			}
		}
	}
}

export class SubscriptionData {
	public allowedTemplates: string[] = [];

	constructor(obj: any = {}) {
		if (typeof obj === 'string') {
			obj = JSON.parse(obj);
		}
		SubscriptionData.buildFromJson(this, obj);
	}

	private static buildFromJson(instance: any, json: any) {
		if (json) {
			for (let prop in json) {
				instance[prop] = json[prop];
			}
		}
	}
}