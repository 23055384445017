import {VueClass, VueDestroyed, VueRequireComponent, VueVar} from "horizon-js-front-sdk/lib/Vue/VueAnnotate";
import html from "./SidebarView.html";
import Vue from "vue";
import {SidebarComponent, SidebarHeader, SidebarMenu} from "vue-interface-sdk/src/component/sidebar/sidebar";
import MPC_PROFILE from "../classes/MPC_PROFILE";
import {Autowire} from "horizon-js-front-sdk/lib/DependencyInjector";
import ProfileRepository from "../repositories/ProfileRepository";
import {FamilyModifier, FamilyRepository} from "../repositories/FamilyRepository";
import Family from "../classes/Family";
import SidebarStateListener from "vue-interface-sdk/src/component/sidebar/SidebarStateListener";
import {HorizonJs_Uuid} from "horizon-js-sdk/lib/HorizonJs_Uuid";
import AuthenticationManager from "horizon-js-front-sdk/lib/AuthenticationManager";
import {Router, RouterNewRouteEvent} from "horizon-js-front-sdk/lib/Router/Router";
import {ModalOrDrawerComponent} from "vue-interface-sdk/src/component/ModalOrDrawer";
import {NavigationRepository} from "../repositories/NavigationRepository";

let el = document.getElementById('sidebar');
if (el) {
	el.innerHTML = html;
}

@VueRequireComponent('sidebar', SidebarComponent)
@VueRequireComponent("modal-or-drawer", ModalOrDrawerComponent)
@VueClass()
export class SidebarView extends Vue implements FamilyModifier {
	@VueVar() profile: MPC_PROFILE | null = null;
	@VueVar() family: Family | null = null;
	@VueVar() families: Family[] = [];

	@VueVar() opened: boolean = false;
	@VueVar() hiddenSidebar: boolean = false;
	@VueVar() minimized: boolean = false;
	@VueVar() hidden: boolean = false;
	@VueVar() openSwitchFamily: boolean = false;

	@VueVar() displayMobileMenu!: boolean;
	@VueVar() header?: SidebarHeader;

	@VueVar() howManyFamilyToChangeOption:number=1;

	@VueVar() menus: SidebarMenu[] = [{
		separator: false,
		navigations: [
			{icon: 'fa-home-heart', title: 'Accueil', url: '/',name:"index"},
			{icon: 'fa-pencil-alt', title: 'Publier', url: '/upload',name:"upload"},
			{icon: 'fa-book-open', title: 'Mon journal', url: '/book',name:"book"},
		]
	}];
	@VueVar() bottom: SidebarMenu[] = [
		{
		separator: true,
		navigations: [
			{icon: 'fa-user-circle', title: 'Famille', url: '/family', name:"family"},
			{icon: 'fa-stars', title: 'Crédits', url: '/credit', name:"credit"},
		]
	}, {
		separator: false,
		navigations: [
			NavigationRepository.get_contact(),
			{icon: 'fa-sign-out-alt', title: 'Déconnexion', url: '/family/disconnect', name:'disconnect'}
		]
	}];

	@VueVar() selectedNavigation?: string;

	/** UUID **/
	@VueVar() uid: string = HorizonJs_Uuid.v4();

	@Autowire(ProfileRepository) profileRepository!: ProfileRepository;
	@Autowire(FamilyRepository) familyRepository!: FamilyRepository;
	@Autowire(SidebarStateListener) sidebarStateListener!: SidebarStateListener;
	@Autowire(Router) router!: Router;

	constructor(container: string, vueConstructor: any = null) {
		super(vueConstructor);
		this.familyRepository.listen(this.uid, this);
		this.router.addEventListener("new_route", this.changeRoute);
		this.sidebarStateListener.listen((event: { type: string, value: any }) => {
			if (event.type === 'openedSidebar') {
				this.opened = event.value;
			} else if (event.type === 'minimizedSidebar') {
				this.minimized = event.value;
			} else if (event.type === 'hiddenSidebar') {
				this.hidden = event.value;
			}
		});

		this.init();
	}

	@VueDestroyed() destruct(){
		this.familyRepository.unListen(this.uid);
	}

	async init() {
		if (this.families.length === 0 && AuthenticationManager.isConnected()) {
			await this.familyRepository.get();
		}
		this.profile = this.profileRepository.get_current();
	}

	current_family_modifier(family: Family) {
		this.family = family;
		this.updateHeader();
	};

	current_families_modifier(families: Family[]) {
		this.families = families;
	}

	change_family(family: Family) {
		this.familyRepository.set_current(family);
		window.location.reload();
	}

	updateHeader(){
		this.header = {
			icon: 'fa-circle-user primary',
			subtitle: this.profile && this.profile.name && this.profile.name !== "Prénom NOM" ? this.profile.name : "Anonyme",
			title: this.family ? this.family.name : '',
			iconAction: this.families.length>1?'fa-repeat':undefined,
			clickable: this.families.length>=this.howManyFamilyToChangeOption
		}
	}

	open_modal_change_family() {
		if (this.families.length >= this.howManyFamilyToChangeOption ) {
			this.openSwitchFamily=true;
		}
	}

	changeRoute = (newRout: RouterNewRouteEvent) => {
		if (newRout && newRout.matchedRoute !== null) {
			this.selectedNavigation = newRout.matchedRoute.location;
		}
	}

	navigationClicked(value:string){
	}

	current_family_credit(credit: number) {
	}
}