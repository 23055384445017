import Repository from "./Repository";
import MPC_PROFILE from "../classes/MPC_PROFILE";
import {Autowire} from "horizon-js-front-sdk/lib/DependencyInjector";
import {NotificationRepository} from "./NotificationRepository";
import Profile from "gaia-sdk-js/lib/src/class/Identity/Profile";
import Session from "../classes/Session";

export interface ProfileModifier {
	ProfileModify(profile: MPC_PROFILE): any;
}

export default class ProfileRepository extends Repository {
	static STORAGE_KEY_PROFILE = 'mpc_profile';
	static STORAGE_KEY_TAKE_OWNERSHIP_PROFILE = 'mpc_take_ownership';

	private l: { id: string, view: ProfileModifier }[] = [];

	@Autowire(NotificationRepository) notificationRepository!: NotificationRepository;

	constructor() {
		super();
	}

	listen(key: string, obj: ProfileModifier): boolean {
		let isKeyAvailable: boolean = true;
		for (let i of this.l) {
			if (i.id === key) {
				isKeyAvailable = false;
				break;
			}
		}
		if (isKeyAvailable) {
			this.l.push({id: key, view: obj});
		}
		return isKeyAvailable;
	}

	unListen(key: string) {
		for (let i in this.l) {
			if (this.l[i].id === key) {
				this.l.splice(parseInt(i), 1);
			}
		}
	}

	private notifyListener(profile: MPC_PROFILE): void {
		for (let l of this.l) {
			try {
				l.view.ProfileModify(profile)
			} catch (error) {
				console.log(error);
			}
		}
	}

	public get(): Promise<MPC_PROFILE> {
		return this.request(this.config.pl_apiUrl + 'v1/Profiles/', null, "GET", true).then((JSONProfile: any) => {
			localStorage.setItem(ProfileRepository.STORAGE_KEY_PROFILE, JSON.stringify(JSONProfile));
			this.notifyListener(new MPC_PROFILE(JSONProfile));

			let profile = new MPC_PROFILE(JSONProfile);
			this.notificationRepository.refresh_token(profile)
			return profile;
		}).catch(function (data: any) {
			return data;
		});
	}

	public update(profile: Profile): Promise<Profile> {
		if (profile.r1_data) {
			return this.request(this.config.pl_apiUrl + 'v1/IdentityGateway/Profiles/' + profile.uid + '/Users/' + profile.r1_data.uid + "/", profile.r1_data.exportToJsonEditable(), "PUT", true).then((JSONProfile: any) => {
				return profile;
			}).catch(function (data: any) {
				return data;
			});
		} else {
			return Promise.reject("r1_data is null");
		}
	}

	public get_current(): MPC_PROFILE | null {
		let c = localStorage.getItem(ProfileRepository.STORAGE_KEY_PROFILE);
		if (c) {
			let profile = new MPC_PROFILE(c);
			this.notificationRepository.refresh_token(profile)
			return profile;
		} else {
			return null;
		}
	}

	public set_take_ownership(uid: string): void {
		localStorage.setItem(ProfileRepository.STORAGE_KEY_TAKE_OWNERSHIP_PROFILE, uid);
	}

	public get_take_ownership(): string | null {
		return localStorage.getItem(ProfileRepository.STORAGE_KEY_TAKE_OWNERSHIP_PROFILE);
	}

	public delete_take_ownership(): void {
		localStorage.removeItem(ProfileRepository.STORAGE_KEY_TAKE_OWNERSHIP_PROFILE);
	}

	public delete_current() {
		localStorage.removeItem(ProfileRepository.STORAGE_KEY_PROFILE);
	}

	public update_anonymous(profile: MPC_PROFILE, name ?: { first_name: string, last_name: string }): Promise<MPC_PROFILE> {
		if (!profile.uid) {
			return this.request(this.config.pl_apiUrl + 'v1/Profiles/', profile.exportToJson(), "POST", false).then((JSONProfile: any) => {
				localStorage.setItem(ProfileRepository.STORAGE_KEY_PROFILE, JSON.stringify(JSONProfile));
				if (name) {
					this.set_anonymous_name(name.first_name, name.last_name);
				}
				this.notifyListener(new MPC_PROFILE(JSONProfile));
				this.notificationRepository.refresh_token(new MPC_PROFILE(JSONProfile))
				return new MPC_PROFILE(JSONProfile);
			});
		} else {
			return this.request(this.config.pl_apiUrl + 'v1/Profiles/' + profile.uid + '/', profile.exportToJson(), "PUT", false).then((JSONProfile: any) => {
				localStorage.setItem(ProfileRepository.STORAGE_KEY_PROFILE, JSON.stringify(JSONProfile));
				if (name) {
					this.set_anonymous_name(name.first_name, name.last_name);
				}

				this.notifyListener(new MPC_PROFILE(JSONProfile));
				this.notificationRepository.refresh_token(new MPC_PROFILE(JSONProfile))
				return new MPC_PROFILE(JSONProfile);
			});
		}
	}

	public update_image(data: string, profile?: MPC_PROFILE): Promise<MPC_PROFILE> {
		return this.request(this.config.pl_apiUrl + 'v1/Profiles/' + (profile && profile.uid ? profile.uid + '/' : '') + 'Image', {uploadBase64: data}, "POST", false).then((JSONdata: any) => {
			return new MPC_PROFILE(JSONdata);
		});
	}

	public take_owner_ship(uid: string): Promise<Profile> {
		// Être connecté et on prend le ownership du profil
		return this.request(this.config.pl_apiUrl + 'v1/Profiles/' + uid + '/TakeOwnership', null, "POST", true).then((JSONdata: any) => {
			return new MPC_PROFILE(JSONdata);
		});
	}

	public set_anonymous_name(first_name: string, last_name: string) {
		localStorage.setItem("anonymous_first_name", first_name);
		localStorage.setItem("anonymous_last_name", last_name);
	}

	public get_anonymous_name(): { firstname: string | null, lastname: string | null } {
		return {
			firstname: localStorage.getItem("anonymous_first_name"),
			lastname: localStorage.getItem("anonymous_last_name")
		}
	}

	delete_anonymous_name() {
		localStorage.removeItem("anonymous_first_name");
		localStorage.removeItem("anonymous_last_name");
	}

	new(): Promise<MPC_PROFILE> {
		let new_profile: MPC_PROFILE = new MPC_PROFILE();
		new_profile.name = 'Prénom NOM';
		return this.update_anonymous(new_profile).then((profile: MPC_PROFILE) => {
			return profile;
		});
	}

	disconnect() {
		Session.clean_mpc_localStorage();
		Session.clean_mpc_connexion_localStorage();
		window.location.href = this.config.identityGateway_apiUrl + 'v1/oauth2/logout?returnUrl='
			+ encodeURIComponent(this.config.pl_serviceUrl ? this.config.pl_serviceUrl : '');
	}
}
