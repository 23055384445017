import GoogleTagManagerData, {GTM_Event} from "./class/GoogleTagManagerData";

export default class GtmManager {
	public static dataLayerName = 'gtmDataLayer';
	public lastContainerId: string|null = null;

	constructor() {
		window[GtmManager.dataLayerName] = window[GtmManager.dataLayerName] || [];
	}

	init(containerId: string) {
		this.lastContainerId=containerId;

		window[GtmManager.dataLayerName] = window[GtmManager.dataLayerName] || [];

		(function(w, d, s, l, i) {
			window[GtmManager.dataLayerName].push({
				'gtm.start': new Date().getTime(),
				event: 'gtm.js'
			});

			let f = d.getElementsByTagName('script')[0],
				j = d.createElement('script');
			j.async = true;
			j.src = 'https://www.googletagmanager.com/gtm.js?id=' + containerId + '&l=' + GtmManager.dataLayerName;
			j.id = 'gtm-script-tag-' + containerId;
			if(f && f.parentNode) {
				f.parentNode.insertBefore(j, f);
			}
		})(window, document, 'script', 'dataLayer', containerId);
	}

	clear() {
		// Empty previous events
		window[GtmManager.dataLayerName] = [];
		// Remove js tag
		let el = document.querySelector('#gtm-script-tag-' + this.lastContainerId);
		if(el){
			el.remove();
		}
	}

	sendEvent(googleTagManagerData: GoogleTagManagerData, event:GTM_Event) {
		try{
			window[GtmManager.dataLayerName].push({
				event: event,
				ecommerce: {
					items: googleTagManagerData.ecommerce && googleTagManagerData.ecommerce.items ? googleTagManagerData.ecommerce.items : null,
					currency: 'EUR',
					value: googleTagManagerData.ecommerce && googleTagManagerData.ecommerce.value ? googleTagManagerData.ecommerce.value : null
				},
				profile: {
					email: googleTagManagerData.email,
					firstname: googleTagManagerData.firstname,
					lastname: googleTagManagerData.lastname
				}
			});
		}catch (e){}

	}
}