export default class Payment {
	public uid: string | null = null;
	public familyUid: string | null = null;
	public paymentUid: string | null = null;
	public chargeUid: string | null = null;
	public profileUid: string | null = null;
	public invoiceUid: string | null = null;
	public parentPaymentUid: string | null = null;
	public subscriptionUid: string | null = null;

	public creationDate!: string;
	public amount!: number;
	public creditedUnits!: number;
	public usedUnits!: number;

	public number: number | null = null;

	public redirectUrl: string | null = null;
	public status: PaymentStatus | null = null;

	constructor(obj: any = {}) {
		if (typeof obj === 'string') {
			obj = JSON.parse(obj);
		}
		Payment.buildFromJson(this, obj);
	}

	private static buildFromJson(instance: Payment, json: any) {
		if (json) {
			for (let prop in json) {
				if (prop === 'redirectUrl') {
					instance[prop] = json[prop] ? instance.htmlDecode(json[prop]) : null;
				} else {
					instance[prop] = json[prop];
				}
			}
		}
	}

	isPending(): boolean {
		return this.status === "PENDING";
	}

	isError(): boolean {
		return this.status === "ERROR";
	}

	isSuccess(): boolean {
		return this.status === "SUCCESS";
	}

	htmlDecode(input) {
		let doc = new DOMParser().parseFromString(input, "text/html");
		return doc.documentElement.textContent;
	}

	static is_last_payment_ok(payments: Payment[]): boolean {
		if (payments[0]) {
			return payments[0].isSuccess();
		}
		return false;
	}

	static is_payment_pending(payments: Payment[]): boolean {
		for (let p of payments) {
			if (p.isPending()) {
				return true;
			}
		}
		return false;
	}

	static status_of_last_payment(payments: Payment[]): PaymentStatus | null {
		let last_payment = Payment.last_payment(payments);

		if (last_payment) {
			return last_payment.status;
		}

		return null;
	}

	static last_payment(payments: Payment[]): Payment | null {
		if (payments.length > 0) {
			let paymentsTmps: Payment[] = [];
			paymentsTmps = paymentsTmps.concat(payments);
			Payment.sort_payment_by_creation_date(paymentsTmps);
			if (paymentsTmps[0]) {
				return paymentsTmps[0];
			}
		}
		return null;
	}

	static sort_payment_by_creation_date(payments: Payment[]) {
		payments.sort(function (a: Payment, b: Payment) {
			if (a.creationDate !== undefined && b.creationDate !== undefined) {
				let dateA = new Date(a.creationDate).getTime();
				let dateB = new Date(b.creationDate).getTime();
				return dateB - dateA;
			}
			return 0;
		});
	}

}

export enum PaymentStatus {
	SUCCESS = "SUCCESS",
	PENDING = "PENDING",
	ERROR = "ERROR"
}