import Repository from "./Repository";
import * as Sentry from "@sentry/browser";
import Profile from "gaia-sdk-js/lib/src/class/Identity/Profile";

export default class ProfileRepository extends Repository {

	constructor(config: { apiUrl: string, accessToken: string }) {
		super(config);
	}

	public get(uid: string): Promise<Profile> {
		return this.request(this.apiUrl + 'v1/Profiles/' + uid + '/',
			null,
			"GET",
			true,
			{headers: this.header}
		).then(function (JSONProfile: any) {
			let user = new Profile(JSONProfile);
			if (user.user && user.user.email)
				Sentry.setUser({
					email: user.user.email,
					id: user.user.uid,
					username: user.getUser()?.first_name + " " + user.getUser()?.last_name
				});
			return user;
		});
	}

	public update(profile: Profile): Promise<Profile> {
		return this.request(this.apiUrl + 'v1/Profiles/' + profile.uid + '/',
			profile.exportToJson(),
			"PUT",
			true,
			{headers: this.header}
		).then(function (JSONProfile: any) {
			return new Profile(JSONProfile);
		});

	}
}
