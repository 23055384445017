import Repository from "./Repository";
import {SidebarNavigation} from "vue-interface-sdk/src/component/sidebar/sidebar";

export class NavigationRepository extends Repository {

	constructor() {
		super();
	}

	static get_contact(): SidebarNavigation {
		return {
			icon: 'fa-paper-plane',
			title: 'Contactez-nous',
			url: 'https://mon-petit-courrier.fr/contacter-lequipe-de-mon-petit-courrier/?utm_source=application'
		}
	}
	static get_faq(): SidebarNavigation {
		return {
			icon: 'fa-question-square',
			title: 'Questions fréquentes',
			url: 'https://mon-petit-courrier.fr/faq/?utm_source=application'
		}
	}
}