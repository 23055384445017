import Repository from "./Repository";
import {Autowire} from "horizon-js-front-sdk/lib/DependencyInjector";
import {Native} from "native-universal-container-bridge/lib/native";
import MPC_PROFILE from "../classes/MPC_PROFILE";

export class NotificationRepository extends Repository {

	@Autowire(Native.PushNotification) pushNotification!: Native.PushNotification;

	constructor() {
		super();
	}

	send(profileUid: string, token: string) {
		this.request(this.config.pl_apiUrl + "v1/" + 'Profiles/' + profileUid + '/RegisterNotificationRegistrationToken/', {
			registrationToken: token
		}, 'PUT', false);
	}

	refresh_token(profile: MPC_PROFILE) {
		this.pushNotification.info().then((value) => {
			if (value.authorizationGivenByUser && !value.needAuthorization) {
				this.pushNotification.subscribe().then((token) => {

					let is_token_already_exist_in_profile: boolean = false;
					for (let profile_token of profile.deviceTokens) {
						if (profile_token.registrationToken === token.token) {
							is_token_already_exist_in_profile = true;
							break;
						}
					}

					if (!is_token_already_exist_in_profile && profile.uid) {
						this.send(profile.uid, token.token);
					}
				});
			}
		}).catch((err: any) => {

		});
	}

	ask_subscription(profileUid: string) {
		this.pushNotification.subscribe().then((token) => {
			this.send(profileUid, token.token);
		});
	}
}