export default class Config {

	/** Plateform **/
	pl_name: string | null = null;
	pl_apiUrl?: string;
	pl_serviceUrl?: string;
	maintenance?: boolean;

	/** Identity **/
	identityGateway_apiUrl?: string;
	identityGateway_serviceUrl?: string;
	identityGateway_clientId?: string;

	/** Invoice **/
	invoiceGateway_apiUrl?: string;
	invoiceGateway_serviceUrl?: string;
	invoiceGateway_clientId?: string;

	/** Apple login **/
	appleClientId?: string;

	/** Gaia **/
	gaiaGateway_apiUrl?: string;
	gaiaGateway_serviceUrl?: string;
	gaiaGateway_clientId?: string;

	/** MDM **/
	mdm_apiUrl?: string;
	mdm_serviceUrl?: string;

	/** Sentry **/
	sentryDsn?: string;
	sentryEnvironment?: string;

	/** GoogleTagManager **/
	gtmId?: string;

	/** Version **/
	version?: string;

	constructor(obj: any = {}) {
		if (typeof obj === 'string') {
			obj = JSON.parse(obj);
		}
		Config.buildFromJson(this, obj);
	}

	private static buildFromJson(instance: any, json: any) {
		if (json) {
			for (let prop in json) {
				if (json.hasOwnProperty(prop)) {
					instance[prop] = json[prop];
				}
			}
		}
	}

	getFromServer(url: string = '/config.json'): Promise<any> {
		let request: any = {
			url: url,
			method: 'GET',
			headers: {}
		};
		return new Promise(function (resolve, reject) {
			$.ajax(request).done(function (returnData: any) {
				resolve(returnData);
			}).fail(function (xhr: any, status: any, error: any) {
				reject();
			});
		});
	}

	isValid(): boolean {
		return false;
	}
}