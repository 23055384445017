export default class Target {
	public name: string = "";
	public familyUid: string | null = null;
	public uid: string | null = null;
	public enabled: boolean = false;
	public valid: boolean = false;
	public creationDate: string | null = null;
	public address: Address = new Address();


	is_anonyme(): boolean {
		return this.name === "Mon destinataire"
			&& this.address
			&& this.address.recipient === "Mon destinataire";
	}

	set_exemple() {
		this.name = "Mon destinataire";
		this.address.set_exemple();
	}

	constructor(obj: any = {}) {
		if (typeof obj === 'string') {
			obj = JSON.parse(obj);
		}
		Target.buildFromJson(this, obj);
	}

	private static buildFromJson(instance: any, json: any) {
		if (json) {
			for (let prop in json) {
				if (prop === 'address') {
					instance[prop] = new Address(json[prop]);
				} else {
					instance[prop] = json[prop];
				}
			}
		}
	}

	exportToJson() {
		let JSONExport: any = {};
		JSONExport.uid = this.uid;
		JSONExport.familyUid = this.familyUid;
		JSONExport.name = this.name;
		JSONExport.enabled = this.enabled;
		JSONExport.address = this.address.exportToJson();
		JSONExport.valid = this.valid;
		JSONExport.creationDate = this.creationDate;
		return JSONExport;
	}
}

export class Address {
	public recipient: string = "";
	public line1: string = "";
	public line2: string = "";
	public city: string = "";
	public zipcode: string = "";
	public country: string = "France";

	constructor(obj: any = {}) {
		if (typeof obj === 'string') {
			obj = JSON.parse(obj);
		}
		Address.buildFromJson(this, obj);
	}

	private static buildFromJson(instance: any, json: any) {
		if (json) {
			for (let prop in json) {
				instance[prop] = json[prop];
			}
		}
	}

	isAdresseExemple(): boolean {
		return this.recipient === "Mon destinataire" &&
			this.line1 === "1 rue de la gare" &&
			this.zipcode === "37000" &&
			this.city === "Tours" &&
			this.country === "France";
	}

	set_exemple(): void {
		this.recipient = "Mon destinataire";
		this.line1 = "1 rue de la gare";
		this.recipient = "Micheline";
		this.zipcode = "37000";
		this.city = "Tours";
		this.country = "France";
	}

	exportToJson() {
		let JSONExport: any = {};
		if (this.recipient)
			JSONExport.recipient = this.recipient;
		if (this.line1)
			JSONExport.line1 = this.line1;
		if (this.line2)
			JSONExport.line2 = this.line2;
		if (this.city)
			JSONExport.city = this.city;
		if (this.zipcode)
			JSONExport.zipcode = this.zipcode;
		if (this.country)
			JSONExport.country = this.country;
		return JSONExport;
	}
}