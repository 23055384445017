import formulaire from './target-formulaire-component.html';
import {Router} from 'horizon-js-front-sdk/lib/Router/Router';
import {VueDestroyed, VueMounted, VueParam, VueVar, VueVirtualComponent} from "horizon-js-front-sdk/lib/Vue/VueAnnotate";
import Target, {Address} from "../../classes/Target";
import {FamilyRepository} from "../../repositories/FamilyRepository";
import {Autowire} from "horizon-js-front-sdk/lib/DependencyInjector";
import {HorizonJs_Uuid} from "horizon-js-sdk/lib/HorizonJs_Uuid";
import Countries, {Country} from "../../classes/Countries";
import {TargetRepository} from "../../repositories/TargetRepository";

Router.getMainRouter()?.injectPageHtml(formulaire);
export class TargetFormulaireComponent extends VueVirtualComponent {

	/** Param **/
	@VueParam() target!: Target;
	@VueParam() config?: { reset: boolean, buttonText?: string, titleText?: string };

	@VueVar() loading!: boolean;

	@VueVar() isTargetAndRecipientDifferent: boolean = false;

	/** Form Validation **/
	@VueVar() createTarget: any = {
		inline: true,
		on: 'blur',
		fields: {
			name: {
				identifier: 'name',
				rules: [
					{
						type: 'empty',
						prompt: "Merci d'indiquer un nom."
					}
				]
			},
			name2: {
				identifier: 'name2',
				rules: [
					{
						type: 'empty',
						prompt: "Merci d'indiquer un nom."
					}
				]
			},
			line1: {
				identifier: 'line1',
				rules: [
					{
						type: 'empty',
						prompt: "Merci d'indiquer une adresse."
					}
				]
			},
			city: {
				identifier: 'city',
				rules: [
					{
						type: 'empty',
						prompt: "Merci d'indiquer une ville."
					}
				]
			},
			zip: {
				identifier: 'zip',
				rules: [
					{
						type: 'empty',
						prompt: "Merci d'indiquer un code postal."
					}
				]
			},
		}
	};


	/** Country **/
	@VueVar() countries: Country[] = Countries.countries;
	/** Repositories **/
	@Autowire(FamilyRepository) familyRepository!: FamilyRepository;
	@Autowire(TargetRepository) targetRepository!: TargetRepository;

	/** Unique string **/
	uid: string = HorizonJs_Uuid.v4();

	constructor() {
		super();
	}
	@VueDestroyed()destroy(){
		console.log("here");
	}

	@VueMounted() mounted() {
		if (this.config && this.config.reset) {
			this.target.name = "";
			this.target.address = new Address();
		}
		this.init_dropdown_country();
	}

	target_name_changed() {
		this.target.address.recipient = this.target.name;
	}

	init_dropdown_country() {
		let values: any[] = [];
		for (let country of this.countries) {
			values.push({
				name: country.name,
				value: country.name,
				icon: country.mini,
				iconClass: 'flag',
				selected: (this.target.address.country === country.name)
			})
		}
		// @ts-ignore
		$('#' + this.uid + ' [data-selector="country_dropdown"]').dropdown({
			values: values,
			onChange: (value: string, text: string, $selectedItem: any) => {
				this.target.address.country = value;
			}
		});
	}

	save() {
		let formulaire = $('#' + this.uid);
		// @ts-ignore
		formulaire.form(this.createTarget);

		// @ts-ignore
		if (this.target && this.target.familyUid && formulaire.form('is valid')) {
			this.loading = true;
			this.targetRepository.update(this.target.familyUid, this.target).then((target: Target) => {
				this.loading = false;
				this.$emit("created", this.target);
			}).catch((xhr: any) => {
				alert(JSON.stringify(xhr));
			})
		} else {
			// @ts-ignore
			formulaire.form('validate form');
		}
	}

	// language=HTML
	static template = formulaire;
}