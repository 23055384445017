import Repository from "./Repository";
import Period from "../classes/Period";
import {MemberRepository} from "./MemberRepository";
import Member from "../classes/Member";


export interface PeriodModifier {
	periods_modified(value: Period[]): void;
}

export class PeriodRepository extends Repository {
	private l: { id: string, view: PeriodModifier }[] = [];
	//Cache
	private static DELAY_OF_CACHE: number = 60 * 1000; // X * 1000 => X in seconde.
	private cache: { [familyUid: string]: { timestamps: number, els: Period[] } } = {};
	private promise: { [familyUid: string]: Promise<Period[]> } = {};

	private cache_periods: { [periodUid: string]: { timestamps: number, el: Period } } = {};
	private promise_period: { [periodUid: string]: Promise<Period> } = {};

	constructor() {
		super();
	}

	listen(key: string, obj: PeriodModifier): boolean {
		let isKeyAvailable: boolean = true;
		for (let i of this.l) {
			if (i.id === key) {
				isKeyAvailable = false;
				break;
			}
		}
		if (isKeyAvailable) {
			this.l.push({id: key, view: obj});
		}
		return isKeyAvailable;
	}

	unListen(key: string) {
		for (let i in this.l) {
			if (this.l[i].id === key) {
				this.l.splice(parseInt(i), 1);
			}
		}
	}

	private fire_modified(value: Period[]) {
		for (let l of this.l) {
			l.view.periods_modified(value);
		}
	}

	public set(familyUid: string, period: any): Promise<any> {
		return this.request(this.config.pl_apiUrl + "v1/" + 'Families/' + familyUid + '/Periods/', period.exportToJson(), 'POST').then((returnJson: any) => {
			return returnJson;
		});
	}

	public get(familyUid: string, forceUpdate: boolean = false): Promise<Period[]> {

		if (!forceUpdate) {
			// Check si la même promesse est déjà entrain d'être demandée au serveur.
			if (this.promise[familyUid] !== undefined) {
				return this.promise[familyUid];
			}

			// Check si un cache est disponible.
			let cache = this.cache[familyUid];
			if (cache !== undefined && cache !== null && cache.timestamps > (new Date().getTime() - PeriodRepository.DELAY_OF_CACHE)) {
				return Promise.resolve(cache.els);
			}
		}

		return this.promise[familyUid] = this.request(this.config.pl_apiUrl + "v1/" + 'Families/' + familyUid + '/Periods/', null, 'GET', false).then((returnJson: any) => {
			let periods: Period[] = [];
			for (let e of returnJson) {
				periods.push(new Period(e));
			}


			// On crée le cache
			this.cache[familyUid] = {
				timestamps: new Date().getTime(),
				els: periods
			};

			// On supprimer la promesse de son cache.
			delete this.promise[familyUid];


			this.fire_modified(periods);
			return periods;
		});
	}

	public getOne(familyUid: string, periodUid: string, forceUpdate: boolean = false): Promise<Period> {
		if (!forceUpdate) {
			// Check si la même promesse est déjà entrain d'être demandée au serveur.
			if (this.promise_period[periodUid] !== undefined) {
				console.log("promise ok");
				return this.promise_period[periodUid];
			}

			// Check si un cache est disponible.
			let cache = this.cache_periods[periodUid];
			if (cache !== undefined && cache !== null && cache.timestamps > (new Date().getTime() - PeriodRepository.DELAY_OF_CACHE)) {
				return Promise.resolve(cache.el);
			}
		}

		return this.promise_period[periodUid] = this.request(this.config.pl_apiUrl + "v1/" + 'Families/' + familyUid + '/Periods/' + periodUid + "/", null, 'GET', false).then((returnJson: any) => {
			let period = new Period(returnJson);

			// On crée le cache
			if (period.uid) {
				this.cache_periods[period.uid] = {
					timestamps: new Date().getTime(),
					el: period
				};
			}


			// On supprimer la promesse de son cache.
			delete this.promise_period[periodUid];

			return period;
		});
	}

	public close(familyUid: string, periodUid: string): Promise<Period> {
		return this.request(this.config.pl_apiUrl + "v1/" + 'Families/' + familyUid + '/Periods/' + periodUid + '/Close', null, 'GET').then((returnJson: any) => {
			return new Period(returnJson);
		});
	}

	public create(familyUid: string): Promise<Period> {
		return this.request(this.config.pl_apiUrl + "v1/" + 'Families/' + familyUid + '/Periods/Create', {}, 'POST').then((returnJson: any) => {
			return new Period(returnJson);
		});
	}


}