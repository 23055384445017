import {VueClass, VueRequireComponent, VueVar, VueWatched} from "horizon-js-front-sdk/lib/Vue/VueAnnotate";
import Vue from "vue";
import {Autowire} from "horizon-js-front-sdk/lib/DependencyInjector";
import Config from "../Config";
import {Router, RouterNewRouteEvent} from "horizon-js-front-sdk/lib/Router/Router";
import Account, {AccountModifier} from "../classes/Account";
import Family from "../classes/Family";
import {FamilyModifier, FamilyRepository} from "../repositories/FamilyRepository";
import {HorizonJs_Uuid} from "horizon-js-sdk/lib/HorizonJs_Uuid";
import AuthenticationManager from "horizon-js-front-sdk/lib/AuthenticationManager";
import Session from "../classes/Session";
import ProfileRepository from "../repositories/ProfileRepository";
import {ModalOrDrawerComponent} from "vue-interface-sdk/src/component/ModalOrDrawer";
import {TargetFormulaireComponent} from "../components/targets/target-formulaire-component";
import {TargetRepository} from "../repositories/TargetRepository";
import Target from "../classes/Target";
import MPC_PROFILE from "../classes/MPC_PROFILE";

@VueRequireComponent("modal-or-drawer", ModalOrDrawerComponent)
@VueRequireComponent("target-formulaire-component", TargetFormulaireComponent)
@VueClass()
export default class NavbarView extends Vue implements AccountModifier, FamilyModifier {

	@VueVar() openMobileMenu: boolean = false;
	@Autowire(Config) config!: Config;
	@Autowire(Router) router!: Router;
	@Autowire(Account) account!: Account;

	@VueVar() isAccountValid: boolean = false;
	@VueVar() display_drawer: boolean = false;
	@VueVar() openSwitchFamily: boolean = false;

	@VueVar() open_drawer_target: boolean = false;

	@VueWatched() isAccountValidWatch() {
		if (this.isAccountValid) {
			this.items_computer = [
				{link: '/', class: 'fa-home-heart', title: 'Accueil', isActive: false},
				{link: '/upload', class: 'fa-pencil-alt', title: 'Publier', isActive: false},
				{link: '/book', class: 'fa-book-open', title: 'Mon journal', isActive: false},
				{link: '/family', class: 'fa-user-circle', title: 'Ma Famille', isActive: false},
				{
					link: 'https://mon-petit-courrier.fr/contacter-lequipe-de-mon-petit-courrier/?utm_source=application',
					class: 'fa-paper-plane',
					title: 'Contactez-nous', isActive: false
				}
			];

			this.items = [
				{link: '/upload', class: 'fa-pencil-alt', title: 'Publier', isActive: false},
				{link: '/book', class: 'fa-book-open', title: 'Mon journal', isActive: false},
				{link: '/family', class: 'fa-user-circle', title: 'Ma Famille', isActive: false},
				{
					link: 'https://mon-petit-courrier.fr/contacter-lequipe-de-mon-petit-courrier/',
					class: 'fa-paper-plane',
					title: "Contactez-nous <i class= 'external link icon fitted tiny'></i>",
					isActive: false
				},
				{
					link: 'https://mon-petit-courrier.fr/faq/?utm_source=application',
					class: 'fa-question-square',
					title: "Questions fréquentes <i class= 'external link icon fitted tiny'></i>",
					isActive: false
				},
			];

			this.init();
		}
	}

	@VueVar() isHomeActive: boolean = false;

	@VueVar() items: { link: string, class: string, title: string, isActive: boolean }[] = [];
	@VueVar() items_computer: { link: string, class: string, title: string, isActive: boolean }[] = [];

	@VueVar() targets: Target[] = [];
	@VueVar() family: Family | null = null;
	@VueVar() profile: MPC_PROFILE | null = null;
	@VueVar() families: Family[] = [];

	/** Rapository **/
	@Autowire(FamilyRepository) familyRepository!: FamilyRepository;
	@Autowire(ProfileRepository) profileRepository!: ProfileRepository;
	@Autowire(TargetRepository) targetRepository!: TargetRepository;

	/** UUID **/
	@VueVar() uid: string = HorizonJs_Uuid.v4();

	constructor(container: string, vueDataConstructor: any = null) {
		super(vueDataConstructor);
		this.router.addEventListener("new_route", this.changeRoute);
		this.account.listen(container, this);
		this.familyRepository.listen(container, this);

		this.profile = this.profileRepository.get_current();
		if (this.families.length === 0 && AuthenticationManager.isConnected()) {
			this.familyRepository.get();
		}

		this.getTargets();
	}

	async getTargets() {
			let family = await this.familyRepository.get_current();
			if (!family || !family.uid) return;
			this.targetRepository.get(family.uid, true).then((targets) => {
				this.targets = targets;
			});
	}

	goToTargets() {
		window.location.href = '/family/targets'
	}


	init() {
		if (new URL(window.location.href).pathname === "/discovery") {

		} else {
			for (let item of this.items_computer) {
				item.isActive = new URL(window.location.href).pathname === item.link
				if (item.title === "Accueil") {
					item.isActive = (new URL(window.location.href).pathname === "/")
						|| new URL(window.location.href).pathname === "/index"
				}
			}
			for (let item of this.items) {
				item.isActive = new URL(window.location.href).pathname === item.link;
			}
			this.isHomeActive = (new URL(window.location.href).pathname === "/")
				|| new URL(window.location.href).pathname === "/index";
		}
	}

	isValidModifier(isValid: boolean) {
		this.isAccountValid = isValid;
	}

	changeRoute = (newRout: RouterNewRouteEvent) => {
		this.openMobileMenu = false;

		if (newRout && newRout.matchedRoute !== null) {
			for (let item of this.items_computer) {
				item.isActive = newRout.matchedRoute.location === item.link.split('/')[1];
				if (item.title === "Accueil") {
					item.isActive = (new URL(window.location.href).pathname === "/")
						|| new URL(window.location.href).pathname === "index"
				}
			}
			for (let item of this.items) {
				item.isActive = newRout.matchedRoute.location === item.link.split('/')[1];
			}
			this.isHomeActive = newRout.matchedRoute.location === "index";
		}

	}

	current_family_modifier(family: Family) {
		this.family = family;
		this.add_family(family);
	}

	current_families_modifier(families: Family[]) {
		this.families = families;
	}

	add_family(family: Family): void {
		for (let f of this.families) {
			if (f.uid === family.uid) {
				return;
			}
		}

		this.families.push(family);
	}

	disconnect() {
		Session.clean_mpc_localStorage();
		Session.clean_mpc_connexion_localStorage();
		window.location.href = this.config.identityGateway_apiUrl + 'v1/oauth2/logout?returnUrl='
			+ encodeURIComponent(this.config.pl_serviceUrl ? this.config.pl_serviceUrl : '');
	}

	open_modal_change_family() {
		if (this.families.length > 1) {
			this.openSwitchFamily = true;
		}
	}

	open_drawer() {
		// if (this.families.length > 1) {
		this.openSwitchFamily = !this.openSwitchFamily;
		// }
	}

	open_drawer_target_function() {
		if (this.family && !this.family.is_anonymous && this.profile && this.family.ownerUid !== this.profile.uid) {
			this.goToTargets();
			return;
		}
		if (this.targets[0] && this.targets[0].is_anonyme()) {
			this.open_drawer_target = !this.open_drawer_target;
			return;
		} else {
			this.goToTargets();
			return;
		}
	}

	change_family(family: Family) {
		this.familyRepository.set_current(family);
		window.location.reload();
	}

	current_family_credit(credit: number) {
	}
}