import {VueClass, VueVar} from "horizon-js-front-sdk/lib/Vue/VueAnnotate";
import {Autowire} from "horizon-js-front-sdk/lib/DependencyInjector";
import Vue from "vue";
import {Router, RouterNewRouteEvent} from "horizon-js-front-sdk/lib/Router/Router";
import Account, {AccountModifier} from "../classes/Account";

@VueClass()
export default class BottomView extends Vue implements AccountModifier {

	@Autowire(Router) router!: Router;
	@Autowire(Account) account!: Account;

	@VueVar() isAccountValid: boolean = false;
	@VueVar() hide: boolean = false;

	/** isAc**/
	@VueVar() isActive: any = {
		home: false,
		book: false,
		publish: false,
		family: false,
		credit: false
	};

	constructor(container: string, vueDataConstructor: any = null) {
		super(vueDataConstructor);
		this.router.addEventListener("new_route", this.changeRoute);
		this.account.listen(container, this);
	}

	isValidModifier(isValid: boolean) {
		this.isAccountValid = isValid;
	}

	changeRoute = (newRout: RouterNewRouteEvent) => {
		this.isActive.home = !!(newRout.matchedRoute && newRout.matchedRoute.location === "index");
		this.isActive.publish = !!(newRout.matchedRoute && newRout.matchedRoute.location === "upload");
		this.isActive.family = !!(newRout.matchedRoute && newRout.matchedRoute.location === "family");
		this.isActive.book = !!(newRout.matchedRoute && newRout.matchedRoute.location === "book");
		this.isActive.credit = !!(newRout.matchedRoute && newRout.matchedRoute.location === "credit");

		this.hide = !!(newRout.matchedRoute
			&& (newRout.matchedRoute.location === "upload"
				|| newRout.matchedRoute.location === "sign"
				|| newRout.matchedRoute.location === "image"
				|| newRout.matchedRoute.location === "discovery"
			));
	}

	displayPopup() {
		if (!this.account.isFamilyValid) {
			let popUp: any = $(".ui.huge");
			popUp.popup("show", {
				position: 'top right',
				popup: $('.custom.popup'),
				target: "[data-name='family tab']",
				on: 'click',
				inline: true,
				onVisible: () => {
					setTimeout(() => {
						popUp.popup('hide');
					}, 3000);
				}
			});
		}
	}
}