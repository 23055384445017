import Repository from "./Repository";
import Family from "../classes/Family";
import Payment from "../classes/Payment";
import Utils from "horizon-js-front-sdk/lib/Utils";
import Member from "../classes/Member";
import User from "gaia-sdk-js/lib/src/class/Identity/User";
import GoogleTagManagerData, {GTM_Event} from "../libs/GoogleTagManager/src/class/GoogleTagManagerData";
import GtmManager from "../libs/GoogleTagManager/src/GTMManager";
import {DependencyInjectorInstance} from "horizon-js-front-sdk/lib/DependencyInjector";
import {FamilyRepository} from "./FamilyRepository";

export class PaymentRepository extends Repository {
	static STORAGE_LAST_INFO_PAYMENT_PENDING = 'mpc_last_info_payment_pending';
	static TIME_BETWEEN_TWO_INFO_PAYMENT_PENDING = 24 * 60 * 60 * 1000;
	static STORAGE_LAST_ASKS = 'mpc_last_asks_payment';
	static STORAGE_NEVER_ASK = 'mpc_never_ask_payment';//Boolean stocked
	static TIME_BETWEEN_TWO_ASK = 7 * 24 * 60 * 60 * 1000;//7 jours

	private gtmManager: GtmManager = DependencyInjectorInstance().getInstance(GtmManager);

	//Cache
	private static DELAY_OF_CACHE: number = 60 * 1000; // X * 1000 => X in seconde.
	private cache: { [familyUid: string]: { timestamps: number, payments: Payment[] } } = {};
	private promise: { [familyUid: string]: Promise<Payment[]> } = {};

	constructor() {
		super();
	}

	public get_payments(familyUid: string): Promise<Payment[]> {
		// Check si la même promesse est déjà entrain d'être demandée au serveur.
		if (this.promise[familyUid] !== undefined) {
			return this.promise[familyUid];
		}

		// Check si un cache est disponible.
		let cache = this.cache[familyUid];
		if (cache !== undefined && cache !== null && cache.timestamps > (new Date().getTime() - PaymentRepository.DELAY_OF_CACHE)) {
			return Promise.resolve(cache.payments);
		}


		return this.promise[familyUid] = this.request(this.config.pl_apiUrl + "v1/" + 'Families/' + familyUid + '/Payments/', null, 'GET', false).then((returnJson: any) => {
			let payments: Payment[] = [];
			for (let r of returnJson) {
				payments.push(new Payment(r));
			}
			payments.sort(function (a: Payment, b: Payment) {
				if (a.creationDate !== undefined && b.creationDate !== undefined) {
					let dateA = new Date(a.creationDate).getTime();
					let dateB = new Date(b.creationDate).getTime();
					return dateB - dateA;
				}
				return 0;
			});

			let currentPaymentUid = Utils.getSearchParametersWithName('paymentUid');
			if (currentPaymentUid !== null && typeof currentPaymentUid === "string") {
				//TODO : améliorer le système pour fire l'événement qu'une seule fois.
				for (let p of payments) {
					if (currentPaymentUid === p.paymentUid) {
						this.sendPaymentSuccessToGTM(null,p.amount);
						//Todo : ne pas mettre le set et le delete dans deux repository différents.
						// localStorage.removeItem(FamilyRepository.STORAGE_KEY_PAYMENT_URL);
					}
				}
			}

			// On crée le cache
			this.cache[familyUid] = {
				timestamps: new Date().getTime(),
				payments: payments
			};

			// On supprimer la promesse de son cache.
			delete this.promise[familyUid];

			return payments;
		});
	}

	private static get_last_ask_payment_is_pending(family: Family): { family_uid: string, timestamp: number } | null {
		for (let la of PaymentRepository.get_last_asks(family)) {
			if (la.family_uid === family.uid) {
				return la;
			}
		}
		return null;
	}

	/** ASK : demande de payment **/
	static ask(family: Family, setTime: boolean = false): boolean {
		if (family.uid) {
			let last_ask = PaymentRepository.get_last_ask(family);
			if (last_ask) {
				if (last_ask.timestamp + PaymentRepository.TIME_BETWEEN_TWO_ASK < new Date().getTime()) {
					if (setTime) {
						PaymentRepository.set_last_ask(family);
					}
					return true
				}
			} else {
				if (setTime) {
					PaymentRepository.set_last_ask(family);
				}
				return true;
			}
		}
		return false;
	}

	private static get_last_ask(family: Family): { family_uid: string, timestamp: number } | null {
		for (let la of PaymentRepository.get_last_asks(family)) {
			if (la.family_uid === family.uid) {
				return la;
			}
		}
		return null;
	}

	private static get_last_asks(family: Family): { family_uid: string, timestamp: number }[] {
		let last_asks: { family_uid: string, timestamp: number }[] = [];
		if (family.uid) {
			let last_asks_tmp: any = localStorage.getItem(PaymentRepository.STORAGE_LAST_ASKS);
			if (last_asks_tmp !== null) {
				last_asks_tmp = JSON.parse(last_asks_tmp);
				if (last_asks_tmp !== null) {
					for (let la of last_asks_tmp) {
						last_asks.push(la)
					}
				}
			}
		}
		return last_asks;
	}

	static set_last_ask(family: Family) {
		let last_asks = PaymentRepository.get_last_asks(family);
		let isIn: boolean = false;
		for (let la of last_asks) {
			if (la.family_uid === family.uid) {
				isIn = true;
				la.timestamp = new Date().getTime();
				break;
			}
		}
		if (!isIn && family.uid) {
			last_asks.push({family_uid: family.uid, timestamp: new Date().getTime()})
		}
		localStorage.setItem(PaymentRepository.STORAGE_LAST_ASKS, JSON.stringify(last_asks));
	}

	/** INFO : payment en cours **/
	static info(family: Family, setTime: boolean = false): boolean {
		if (family.uid) {
			let last_ask = PaymentRepository.get_last_info(family);
			if (last_ask) {
				if (last_ask.timestamp + PaymentRepository.TIME_BETWEEN_TWO_INFO_PAYMENT_PENDING < new Date().getTime()) {
					if (setTime) {
						PaymentRepository.set_last_info(family);
					}
					return true
				}
			} else {
				if (setTime) {
					PaymentRepository.set_last_info(family);
				}
				return true;
			}
		}
		return false;
	}

	private static get_last_info(family: Family): { family_uid: string, timestamp: number } | null {
		for (let la of PaymentRepository.get_last_infos(family)) {
			if (la.family_uid === family.uid) {
				return la;
			}
		}
		return null;
	}

	private static get_last_infos(family: Family): { family_uid: string, timestamp: number }[] {
		let last_asks: { family_uid: string, timestamp: number }[] = [];
		if (family.uid) {
			let last_asks_tmp: any = localStorage.getItem(PaymentRepository.STORAGE_LAST_INFO_PAYMENT_PENDING);
			if (last_asks_tmp !== null) {
				last_asks_tmp = JSON.parse(last_asks_tmp);
				if (last_asks_tmp !== null) {
					for (let la of last_asks_tmp) {
						last_asks.push(la)
					}
				}
			}
		}
		return last_asks;
	}

	static set_last_info(family: Family) {
		let last_asks = PaymentRepository.get_last_infos(family);
		let isIn: boolean = false;
		for (let la of last_asks) {
			if (la.family_uid === family.uid) {
				isIn = true;
				la.timestamp = new Date().getTime();
				break;
			}
		}
		if (!isIn && family.uid) {
			last_asks.push({family_uid: family.uid, timestamp: new Date().getTime()})
		}
		localStorage.setItem(PaymentRepository.STORAGE_LAST_INFO_PAYMENT_PENDING, JSON.stringify(last_asks));
	}

	private sendPaymentSuccessToGTM(user: User | null = null, amount: number = 590) {
		//TODO : protéger contre le ouble envoie + envoie d'un élément unique.
		try {
			/** Envoie à GTM **/
			let GTMData: GoogleTagManagerData = new GoogleTagManagerData();
			GTMData.ecommerce = {
				value: amount / 100,//Prix total
				currency: 'EUR',
				items: [{item_id: "mpc", price: amount, item_name: "Journal", quantity: 1}],
			}
			if (user && user.email) {
				GTMData.profile = {email: user.email};
				if (user.first_name)
					GTMData.profile.firstname = user.first_name;

				if (user.last_name)
					GTMData.profile.lastname = user.last_name;

			}
			this.gtmManager.sendEvent(GTMData, GTM_Event.PURCHASE);
		} catch (e) {
		}

		// window.dataLayer.push({
		// 	'event': 'payment_success',
		// 	'family_uid': familyUid,
		// 	'payment_amount': p.amount,
		// 	'subcription_uid': p.subscriptionUid
		// });
	}

}