import Repository from "./Repository";
import Target from "../classes/Target";
import Family from "../classes/Family";
import {FamilyRepository} from "./FamilyRepository";
import {DependencyInjectorInstance} from "horizon-js-front-sdk/lib/DependencyInjector";

export class TargetRepository extends Repository {
	static STORAGE_LAST_ASKS = 'mpc_last_asks_target';
	static STORAGE_NEVER_ASK = 'mpc_never_ask_target';//Boolean stocked
	static TIME_BETWEEN_TWO_ASK = 7 * 24 * 60 * 60 * 1000;//7 jours

	//Cache
	private static DELAY_OF_CACHE: number = 60 * 1000; // X * 1000 => X in seconde.
	private cache: { [familyUid: string]: { timestamps: number, targets: Target[] } } = {};
	private promise: { [familyUid: string]: Promise<Target[]> } = {};

	private familyRepository: FamilyRepository = DependencyInjectorInstance().getInstance(FamilyRepository);

	constructor() {
		super();
	}

	public get(familyUid: string, force: boolean = false): Promise<Target[]> {

		// Check si la même promesse est déjà entrain d'être demandée au serveur.
		if (!force && this.promise[familyUid] !== undefined) {
			return this.promise[familyUid].then((targets: Target[]) => {
				let target_tmp: Target[] = [];
				for (let target of targets) {
					// Pou ravoir un tableau différent par vue demandant le pointeur.
					target_tmp.push(new Target(target.exportToJson()));
				}
				return target_tmp;
			});
		}

		// Check si un cache est disponible.
		let cache = this.cache[familyUid];
		if (!force && cache !== undefined && cache !== null && cache.timestamps > (new Date().getTime() - TargetRepository.DELAY_OF_CACHE)) {
			// Cette partie sert à ne pas renvoyer le même objet aux vues qui l'on demandés, sous peine de modifier le même objet.
			let targets: Target[] = [];
			for (let target of cache.targets) {
				targets.push(new Target(target.exportToJson()));
			}
			return Promise.resolve(targets);
		}

		return this.promise[familyUid] = this.request(this.config.pl_apiUrl + "v1/" + 'Families/' + familyUid + '/Targets/', null, 'GET', false).then((returnJson: any) => {
			let targets: Target[] = [];

			// Pour éviter que l'on ait un souci de pointeur avec les vues.
			let targetsInCache: Target[] = [];
			for (let t of returnJson) {
				targets.push(new Target(t));
				targetsInCache.push(new Target(t));
			}

			// On crée le cache
			this.cache[familyUid] = {
				timestamps: new Date().getTime(),
				targets: targetsInCache
			};

			// On supprimer la promesse de son cache.
			delete this.promise[familyUid];

			return targets;
		}).catch((xhr: any) => {
			if (xhr.status === 404) {
				// Aucune target de trouvée.
				this.familyRepository.get_current(true);
			}
			return [];
		});
	}

	public disable(familyUid: string, targetUid: string): Promise<Target> {
		return this.request(this.config.pl_apiUrl + "v1/" + 'Families/' + familyUid + '/Targets/' + targetUid + '/Disable', {}, 'PUT').then((returnJson: any) => {
			this.update_cache_target(new Target(returnJson));
			return new Target(returnJson);
		});
	}

	public enable(familyUid: string, targetUid: string, activeNow: boolean = false): Promise<any> {
		return this.request(this.config.pl_apiUrl + "v1/" + 'Families/' + familyUid + '/Targets/' + targetUid + '/Enable', undefined, 'PUT').then((returnJson: any) => {
			this.update_cache_target(new Target(returnJson));
			return new Target(returnJson);
		});
	}

	public addToPeriod(familyUid: string, periodUid: string, targetUid: string): Promise<any> {
		return this.request(this.config.pl_apiUrl + "v1/" + 'Families/' + familyUid + '/Periods/' + periodUid + '/Targets/' + targetUid + '/', undefined, 'POST').then((returnJson: any) => {
			this.familyRepository.getCredit(familyUid);
		});
	}

	public deleteToPeriod(familyUid: string, periodUid: string, targetUid: string): Promise<any> {
		return this.request(this.config.pl_apiUrl + "v1/" + 'Families/' + familyUid + '/Periods/' + periodUid + '/Targets/' + targetUid + '/', undefined, 'DELETE').then((returnJson: any) => {
			return this.familyRepository.getCredit(familyUid);
		});
	}

	public disable_multiple(familyUid: string, targets: Target[]): Promise<Target[]> {
		let waitingPromise: Promise<any>[] = [];
		for (let target of targets) {
			if (target.uid) {
				waitingPromise.push(this.disable(familyUid, target.uid));
			}
		}
		return Promise.all(waitingPromise).then((targets: Target[]) => {
			return targets;
		});
	}

	public update(familyUid: string, target: Target): Promise<Target> {
		if (target.uid === null) {
			return this.request(this.config.pl_apiUrl + "v1/" + 'Families/' + familyUid + '/Targets/', target.exportToJson(), 'POST', false).then((returnJson: any) => {
				// @ts-ignore
				window.dataLayer.push({
					'event': 'create_target',
					'family_uid': familyUid,
					'target_enabled': target.enabled,
					'target_name': target.name
				});

				this.update_cache_target(new Target(returnJson));
				return new Target(returnJson);
			});
		} else {
			return this.request(this.config.pl_apiUrl + "v1/" + 'Families/' + familyUid + '/Targets/' + target.uid + '/', target.exportToJson(), 'PUT', false).then((returnJson: any) => {
				this.update_cache_target(new Target(returnJson))
				return new Target(returnJson);
			});
		}

	}

	static ask(family: Family, setTime: boolean = false): boolean {
		if (family.uid) {
			let last_ask = TargetRepository.get_last_ask(family);
			if (last_ask) {
				if (last_ask.timestamp + TargetRepository.TIME_BETWEEN_TWO_ASK < new Date().getTime()) {
					if (setTime) {
						TargetRepository.set_last_ask(family);
					}
					return true
				}
			} else {
				if (setTime) {
					TargetRepository.set_last_ask(family);
				}
				return true;
			}
		}
		return false;
	}

	private static get_last_ask(family: Family): { family_uid: string, timestamp: number } | null {
		for (let la of TargetRepository.get_last_asks(family)) {
			if (la.family_uid === family.uid) {
				return la;
			}
		}
		return null;
	}

	private static get_last_asks(family: Family): { family_uid: string, timestamp: number }[] {
		let last_asks: { family_uid: string, timestamp: number }[] = [];
		if (family.uid) {
			let last_asks_tmp: any = localStorage.getItem(TargetRepository.STORAGE_LAST_ASKS);
			if (last_asks_tmp !== null) {
				last_asks_tmp = JSON.parse(last_asks_tmp);
				if (last_asks_tmp !== null) {
					for (let la of last_asks_tmp) {
						last_asks.push(la)
					}
				}
			}
		}
		return last_asks;
	}

	static set_last_ask(family: Family) {
		let last_asks = TargetRepository.get_last_asks(family);
		let isIn: boolean = false;
		for (let la of last_asks) {
			if (la.family_uid === family.uid) {
				isIn = true;
				la.timestamp = new Date().getTime();
				break;
			}
		}
		if (!isIn && family.uid) {
			last_asks.push({family_uid: family.uid, timestamp: new Date().getTime()})
		}
		localStorage.setItem(TargetRepository.STORAGE_LAST_ASKS, JSON.stringify(last_asks));
	}

	private update_cache_target(target: Target) {
		if (target.familyUid && this.cache[target.familyUid]) {
			for (let target_in_cache of this.cache[target.familyUid].targets) {
				if (target_in_cache.uid === target.uid) {
					target_in_cache.enabled = target.enabled;
					target_in_cache.name = target.name;
					target_in_cache.valid = target.valid;
					target_in_cache.address = target.address;
					target_in_cache.uid = target.uid;
					target_in_cache.familyUid = target.familyUid;
					target_in_cache.creationDate = target.creationDate;
					return;
				}

			}

			// Si on se trouve ici c'est que la target n'existait pas dans le cache.
			this.cache[target.familyUid].targets.push(
				new Target(target.exportToJson())
			);
		}
	}
}