import * as Sentry from "@sentry/browser";
import {Integrations} from "@sentry/tracing";

export class SentryJs {
	static init(dsn: string, environment: string = "production") {
		try {
			Sentry.init({
				dsn: dsn,
				integrations: [new Integrations.BrowserTracing(), new Sentry.Integrations.GlobalHandlers({
					onerror: true,
					onunhandledrejection: true
				}), new Sentry.Integrations.TryCatch({eventTarget: false})],
				environment: environment,
				// Set tracesSampleRate to 1.0 to capture 100%
				// of transactions for performance monitoring.
				// We recommend adjusting this value in production
				tracesSampleRate: 1.0,

			});
		} catch (e) {
			console.warn("Sentry not initialized");
		}
	}
}