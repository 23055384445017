import Family from "../classes/Family";
import Period from "../classes/Period";
import {Autowire} from "horizon-js-front-sdk/lib/DependencyInjector";
import {FamilyRepository} from "../repositories/FamilyRepository";
import {Router} from "horizon-js-front-sdk/lib/Router/Router";

import Payment from "./Payment";
import {PeriodModifier, PeriodRepository} from "../repositories/PeriodRepository";
import {HorizonJs_Uuid} from "horizon-js-sdk/lib/HorizonJs_Uuid";
import Utils from "horizon-js-front-sdk/lib/Utils";
import {MemberRepository} from "../repositories/MemberRepository";
import Session from "./Session";
import Member from "./Member";
import AuthenticationManager from "horizon-js-front-sdk/lib/AuthenticationManager";
import {SubscriptionRepository} from "../repositories/SubscriptionRepository";
import {PaymentRepository} from "../repositories/PaymentRepository";

export interface AccountModifier {
	isValidModifier(isValid: boolean): void;
}

export default class Account implements PeriodModifier {


	private l: { id: string, view: AccountModifier }[] = [];

	periods: Period[] = [];
	payments: Payment[] = [];
	family?: Family;

	private _isFamilyValid: boolean = false;
	get isFamilyValid(): boolean {
		return this._isFamilyValid;
	}

	set isFamilyValid(value: boolean) {
		this._isFamilyValid = value;
		this.fire_is_valid_modifier(this._isFamilyValid);
	}

	@Autowire(PaymentRepository) paymentRepository!: PaymentRepository;
	@Autowire(FamilyRepository) familyRepository!: FamilyRepository;
	@Autowire(PeriodRepository) periodRepository!: PeriodRepository;
	@Autowire(MemberRepository) memberRepository!: MemberRepository;
	@Autowire(SubscriptionRepository) subscriptionRepository!: SubscriptionRepository;
	@Autowire(Router) router!: Router;

	/** Implements PeriodModifier **/
	periods_modified(periods: Period[]) {
		this.periods = periods;
		if (this.periods.length > 0) {
			this.isFamilyValid = true;
		}
	}

	/** Unique identifiant **/
	uid: string = HorizonJs_Uuid.v4();

	constructor() {
		this.periodRepository.listen(this.uid, this);
		let waitingPromise: Promise<any>[] = [];
		let familyToAdd = Utils.getSearchParametersWithName('shareFamilyLink');
		if (familyToAdd !== null && typeof familyToAdd === "string") {
			this.familyRepository.set_invit_link(familyToAdd);
		}

		let source: string | null = this.familyRepository.get_current_source(true);
		if (source !== null) {
			this.familyRepository.set_current_source(source);
		}
		if (AuthenticationManager.isConnected()) {
			let JWT: string | null = Session.getOauthToken();
			if (JWT) {
				let userId: string = Utils.parseJwt(JWT).userId;
				if (userId) {
					let waitingPromises: Promise<any>[] = [];
					let number_of_invitation: number = this.familyRepository.get_invit_link().length;
					for (let fl of this.familyRepository.get_invit_link()) {
						let familyMember: Member = new Member();
						familyMember.familyUid = fl;
						familyMember.profileUid = userId;
						waitingPromises.push(this.memberRepository.set(fl, familyMember).then((family) => {
							// @ts-ignore
							$('body').toast({
								class: 'green',
								message: `Vous avez bien rejoint la famille.`,
								showIcon: 'check',
								showProgress: 'bottom',
							});
						}));
					}
					Promise.all(waitingPromises).then(() => {
						this.familyRepository.delete_invit_link();
						if (number_of_invitation > 0) {
							window.location.href = "/";
						}
					})
				}
			}


		}

		Promise.all(waitingPromise).then(() => {
			$('#splashscreen').hide();
			this.isFamilyValid = true;
		})
	}

	listen(key: string, obj: AccountModifier): boolean {
		let isKeyAvailable: boolean = true;
		for (let i of this.l) {
			if (i.id === key) {
				isKeyAvailable = false;
				break;
			}
		}
		if (isKeyAvailable) {
			this.l.push({id: key, view: obj});
		}
		return isKeyAvailable;
	}

	unListen(key: string) {
		for (let i in this.l) {
			if (this.l[i].id === key) {
				this.l.splice(parseInt(i), 1);
			}
		}
	}

	fire_is_valid_modifier(value: boolean) {
		for (let l of this.l) {
			l.view.isValidModifier(value);
		}
	}

	isValid(route: string = "family", changeRoute: boolean = true): Promise<boolean> {
		return this.isFamilySetup(route).then(() => {
			if (this.family && this.family.uid) {
				return this.isPeriodSetup(this.family.uid, route, changeRoute).then((aa) => {
					this.isFamilyValid = true;
					if (this.family && this.family.uid) {
						return this.isPaymentSetup(this.family.uid, route, changeRoute).then((payments: Payment[]) => {
							return true;
						});
					}
					return false;
				});
			}
			return false;
		});
	}

	getFamily(): Promise<Family | null> {
		return this.familyRepository.get_current().then((familyOrNull: Family | null) => {
			if (familyOrNull !== null) {
				this.family = familyOrNull;
				return this.family;
			} else {
				return null;
			}

		});
	}

	isFamilySetup(route: string, changeRoute: boolean = true): Promise<Family> {

		return this.getFamily().then((familyOrNull: Family | null) => {
			if (familyOrNull === null) {
				if (changeRoute) {
					this.router.changePage(route);
				}
				return Promise.reject("NO_FAMILY");
			}
			return familyOrNull;
		});
	}

	getPeriods(familyUid: string): Promise<Period[]> {
		return this.periodRepository.get(familyUid).then((periods: Period[]) => {
			this.periods = periods;
			return periods;
		});
	}

	isPeriodSetup(familyUid: string, route: string, changeRoute: boolean = true): Promise<Period[]> {
		return this.getPeriods(familyUid).then((periods: Period[]) => {
			if (this.periods.length === 0) {
				if (changeRoute) {
					this.router.changePage(route);
				}
				return Promise.reject("NO_PERIOD");
			}
			return periods;
		});
	}


	getPayments(familyUid: string): Promise<Payment[]> {
		return this.paymentRepository.get_payments(familyUid).then((payments: Payment[]) => {
			this.payments = payments;
			return payments;
		});
	}

	isPaymentSetup(familyUid: string, route: string, changeRoute: boolean = true): Promise<Payment[]> {
		return this.getPayments(familyUid).then((payments: Payment[]) => {
			let is_period_ok: boolean = false;
			for (let p of this.periods) {
				is_period_ok = p.is_current_period();
				if (is_period_ok) {
					break;
				}
			}
			if (!Payment.is_last_payment_ok(payments) && !is_period_ok) {
				if (changeRoute) {
					/// <reference path="../components/family-component.ts" />
					// Le component family prend le relai.
					this.router.changePage(route);
				}
				return Promise.reject("NO_PAYMENT");
			}
			return payments;
		});
	}
}

export class AccountFamily {
	family!: Family;
	periods: Period[] = [];
	payments: Payment[] = [];

	constructor() {
	}
}