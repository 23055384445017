

export default class Member {
	public creationDate!: string;
	public familyUid: string | null = null;
	public name!: string;
	public imageUrl: string|null=null;
	public profileUid: string | null = null;
	public anonymous: boolean = false;

	constructor(obj: any = {}) {
		if (typeof obj === 'string') {
			obj = JSON.parse(obj);
		}
		Member.buildFromJson(this, obj);
	}

	private static buildFromJson(instance: any, json: any) {
		if (json) {
			for (let prop in json) {
				// if (prop === 'data') {
				// 	instance[prop] = new EventData(json[prop]);
				// } else {
				instance[prop] = json[prop];
				// }
			}
		}
	}

	exportToJson():any{
		let JSONExport: any = {};
		JSONExport.creationDate = this.creationDate;
		JSONExport.familyUid = this.familyUid;
		JSONExport.name = this.name;
		JSONExport.profileUid = this.profileUid;
		return JSONExport;
	}

}