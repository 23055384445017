import {Autowire} from "horizon-js-front-sdk/lib/DependencyInjector";
import AuthentificationManager from "horizon-js-front-sdk/lib/AuthenticationManager";
import Config from "../Config";

export default class Repository {
	public static GET = 'GET';
	public static POST = 'POST';
	public static PUT = 'PUT';
	public static DELETE = 'DELETE';

	@Autowire(Config) config!: Config;

	constructor() {
	}

	private _handleError: (xhr: any, status: any, error: any) => any = function (xhr: any, status: any, error: any) {
	};

	set handleError(value: (xhr: any, status: any, error: any) => any) {
		this._handleError = value;
	}

	protected request(url: string, data: any = null, method: 'GET' | 'POST' | 'PUT' | 'DELETE' = 'GET', requireAuth = true, specificParameters: any = {}): any {
		let self = this;
		let request: any = {
			url: url,
			method: method,
			headers: {},
		};

		if (method == 'GET') {
			request.data = undefined;
		} else {
			request.data = JSON.stringify(data);
			request.contentType = "application/json; charset=utf-8";
			request.dataType = typeof specificParameters.dataType !== 'undefined' ? specificParameters.dataType : "json";

			if (specificParameters["onprogress"] !== undefined) {
				request.xhr = function () {
					let xhr = new window.XMLHttpRequest();
					xhr.upload.addEventListener("progress", function (evt) {
						if (evt.lengthComputable) {
							let percentComplete = (evt.loaded / evt.total) * 100;
							window.dispatchEvent(new CustomEvent(specificParameters["onprogress"].eventName, {"detail": percentComplete}));
						}
					}, false);
					return xhr;
				};
			}
		}

		if (AuthentificationManager.isConnected()) {
			request.headers['authorization'] = AuthentificationManager.getAuthentificationHeader();
		} else {
			localStorage.removeItem('oauth-token');
			if (requireAuth) {
				let currentUrl = window.location.href;
				window.location.href = this.config.pl_serviceUrl + 'auth?redirectAfter=' + encodeURIComponent(currentUrl);
			}
		}

		let xhrFallback = false;

		for (let key in specificParameters) {
			if (key === 'arraybuffer' && specificParameters[key] === true) {
				xhrFallback = true;
			} else
				request[key] = specificParameters[key];
		}

		return new Promise(function (resolve, reject) {
			if (xhrFallback) {
				let xhr = new XMLHttpRequest();
				xhr.responseType = 'arraybuffer';
				xhr.onload = function () {
					resolve(xhr.response);
				};
				xhr.onerror = function () {
					reject(xhr);
				};

				xhr.open(request.method, request.url, true);
				for (let header in request.headers) {
					xhr.setRequestHeader(header, request.headers[header]);
				}
				xhr.send(request.data);
			} else {
				$.ajax(request)
					.done(function (returnData: any) {
						resolve(returnData);
					})
					.fail(function (xhr: any, status: any, error: any) {
						// console.log('fail', xhr, status, error);
						if (self._handleError(xhr, status, error))
							reject();
						else
							reject(xhr);
					});
			}
		});
	};
}