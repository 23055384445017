import Repository from "./Repository";
import Member from "../classes/Member";
import Family from "../classes/Family";

export interface MemberModifier {
	member_modifier(value: Member[]): void;
}

export class MemberRepository extends Repository {
	static STORAGE_SKIP_PERSONALIZATION = 'mpc_skip_personalization';
	static STORAGE_LAST_ASK_INVITATION = 'mpc_last_ask_invitation';
	static STORAGE_NEVER_ASK_INVITATION = 'mpc_never_ask_invitation';//Boolean stocked
	static TIME_BETWEEN_TWO_ASK = 24 * 60 * 60 * 1000;//7 jours

	private l: { id: string, view: MemberModifier }[] = [];

	//Cache
	private static DELAY_OF_CACHE: number = 60 * 1000; // X * 1000 => X in seconde.
	private cache: { [familyUid: string]: { timestamps: number, members: Member[] } } = {};
	private promise: { [familyUid: string]: Promise<Member[]> } = {};

	constructor() {
		super();
	}

	listen(key: string, obj: MemberModifier): boolean {
		let isKeyAvailable: boolean = true;
		for (let i of this.l) {
			if (i.id === key) {
				isKeyAvailable = false;
				break;
			}
		}
		if (isKeyAvailable) {
			this.l.push({id: key, view: obj});
		}
		return isKeyAvailable;
	}

	unListen(key: string) {
		for (let i in this.l) {
			if (this.l[i].id === key) {
				this.l.splice(parseInt(i), 1);
			}
		}
	}

	fire_payment_modified(value: Member[]) {
		for (let l of this.l) {
			l.view.member_modifier(value);
		}
	}

	public get(familyUid: string, forceUpdate:boolean=false): Promise<Member[]> {

		if(!forceUpdate){
			// Check si la même promesse est déjà entrain d'être demandée au serveur.
			if (this.promise[familyUid] !== undefined) {
				return this.promise[familyUid];
			}

			// Check si un cache est disponible.
			let cache = this.cache[familyUid];
			if (cache !== undefined && cache !== null && cache.timestamps > (new Date().getTime() - MemberRepository.DELAY_OF_CACHE)) {
				return Promise.resolve(cache.members);
			}
		}


		return this.promise[familyUid] = this.request(this.config.pl_apiUrl + "v1/" + 'Families/' + familyUid + '/Members/', null, 'GET', false).then((returnJson: any) => {
			let members: Member[] = [];
			let members_local: Member[] = [];
			for (let m of returnJson) {
				members.push(new Member(m));
				members_local.push(new Member(m));
			}

			// On crée le cache
			this.cache[familyUid] = {
				timestamps: new Date().getTime(),
				members: members
			};

			// On supprimer la promesse de son cache.
			delete this.promise[familyUid];

			this.fire_payment_modified(members);
			return members;
		}).catch((e:any)=>{
			if(e &&e.status===500 && e.responseJSON && e.responseJSON.error==="identity_gateway_unavailable"){
				// @ts-ignore
				$('body').toast({
					class: 'error',
					title: `Plateforme de connexion inaccessible.`,
					message: `Si le problème persiste d'ici quelques minutes n'hésitez pas à nous contacter.`,
					showIcon: 'times',
					showProgress: 'bottom',
				});
			}
			return Promise.reject();
		});
	}

	public set(familyUid: string, member: Member): Promise<Member> {
		return this.request(this.config.pl_apiUrl + "v1/" + 'Families/' + familyUid + '/Members/', member.exportToJson(), 'POST', false).then((returnJson: any) => {
			return new Member(returnJson);
		});
	}

	static ask_invitation(family: Family): boolean {
		if (family.uid) {
			let last_ask = MemberRepository.get_last_ask();
			if (last_ask) {
				if (last_ask + MemberRepository.TIME_BETWEEN_TWO_ASK < new Date().getTime()) {
					return true;
				}
			} else {
				return true;
			}
		}

		return false;
	}

	static skip_personalisation(family: Family): boolean {
		if (family.uid) {
			let skip = MemberRepository.get_skip_personalisation();
			if (skip) {
				return true;
			} else {
				this.set_skip_personalisation();
				return true;
			}
		}

		return false;
	}

	static get_skip_personalisation(): boolean {
		let skip = localStorage.getItem(MemberRepository.STORAGE_SKIP_PERSONALIZATION);
		return skip === 'true';
	}

	static set_skip_personalisation() {
		localStorage.setItem(MemberRepository.STORAGE_SKIP_PERSONALIZATION, JSON.stringify(true))
	}

	private static get_last_ask(): number | null {
		let last_ask = localStorage.getItem(MemberRepository.STORAGE_LAST_ASK_INVITATION);
		if (last_ask !== null) {
			return parseInt(last_ask);
		}
		return last_ask;
	}

	static set_last_ask() {
		localStorage.setItem(MemberRepository.STORAGE_LAST_ASK_INVITATION, JSON.stringify(new Date().getTime()))
	}

	new(profileUid: string, name: string, familyUid: string): Promise<Member> {
		let new_member = new Member();
		new_member.name = name;
		new_member.profileUid = profileUid;
		new_member.familyUid = familyUid;
		return this.set(new_member.familyUid, new_member).then((member: Member) => {
			return member;
		});
	}

}