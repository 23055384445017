import moment from "moment";
import Target from "./Target";

export default class Period {
	public uid: string | null = null;
	public familyUid: string | null = null;
	public paymentUid: string | null = null;
	public bookUid: string | null = null;
	public dateStart!: string;
	public dateEnd!: string;

	public totalEventsSizeOnPage?: number;
	public targets?: Target[];
	public postalPrice?: number;
	public number?: number;
	public creationDate?: string;

	is_in_futur(): boolean {
		let now = new Date();
		return new Date(this.dateStart) > now;
	}

	is_current_period(): boolean {
		let now = moment().startOf('day');
		return moment(this.dateStart) <= now && moment(this.dateEnd) >= now;
	}

	constructor(obj: any = {}) {
		if (typeof obj === 'string') {
			obj = JSON.parse(obj);
		}
		Period.buildFromJson(this, obj);
	}

	private static buildFromJson(instance: any, json: any) {
		if (json) {
			for (let prop in json) {
				if (prop === 'targets' && json[prop]) {
					instance[prop] = [];
					for (let target of json[prop]) {
						instance[prop].push(new Target(target));
					}
				} else {
					instance[prop] = json[prop];
				}
			}
		}
	}

	update(period: Period) {
		if (period.uid) this.uid = period.uid;
		if (period.familyUid) this.familyUid = period.familyUid;
		if (period.paymentUid) this.paymentUid = period.paymentUid;
		if (period.bookUid) this.bookUid = period.bookUid;
		if (period.dateStart) this.dateStart = period.dateStart;
		if (period.dateEnd) this.dateEnd = period.dateEnd;

		if (period.targets) this.targets = period.targets;
		if (period.totalEventsSizeOnPage) this.totalEventsSizeOnPage = period.totalEventsSizeOnPage;
		if (period.totalEventsSizeOnPage) this.totalEventsSizeOnPage = period.totalEventsSizeOnPage;
		if (period.postalPrice) this.postalPrice = period.postalPrice;
		if (period.number) this.number = period.number;
		if (period.creationDate) this.creationDate = period.creationDate;
	}
}