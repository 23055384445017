export default class MPC_PROFILE {
	public uid: string | null = null;
	public image: string | null = null;
	public imageUrl: string | null = null;
	public invoiceAddressUid: string | null = null;
	public subscriptionUid: string | null = null;

	public switchlyUserUid: string | null = null;
	public switchlyPaymentUid: string | null = null;

	// Deprecated
	public paymentUid: string | null = null;

	public nameUpdateDate: string | null = null;
	public creationDate: string | null = null;
	public name: string | null = null;
	public anonymous: boolean = false;
	public deviceTokens: { profileUid: string, registrationToken: string }[] = [];

	constructor(obj: any = {}) {
		if (typeof obj === 'string') {
			obj = JSON.parse(obj);
		}
		MPC_PROFILE.buildFromJson(this, obj);
	}

	private static buildFromJson(instance: any, json: any) {
		if (json) {
			for (let prop in json) {
				instance[prop] = json[prop];
			}
		}
	}

	exportToJson(): any {
		let JSONCharacteristic: any = {};
		JSONCharacteristic.uid = this.uid;
		JSONCharacteristic.name = this.name;
		JSONCharacteristic.image = this.image;
		JSONCharacteristic.imageUrl = this.imageUrl;
		JSONCharacteristic.invoiceAddressUid = this.invoiceAddressUid;
		JSONCharacteristic.subscriptionUid = this.subscriptionUid;
		JSONCharacteristic.switchlyUserUid = this.switchlyUserUid;
		JSONCharacteristic.paymentUid = this.paymentUid;
		JSONCharacteristic.nameUpdateDate = this.nameUpdateDate;
		JSONCharacteristic.creationDate = this.creationDate;
		return JSONCharacteristic;
	}
}