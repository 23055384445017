import Repository from "./Repository";
import Subscription from "../classes/Subscription";
import Utils from "horizon-js-front-sdk/lib/Utils";

export class SubscriptionRepository extends Repository {
	private static LOCAL_STORAGE_PROMOCODE = 'mpc_pc';

	//Cache
	private static DELAY_OF_CACHE: number = 60 * 1000; // X * 1000 => X in seconde.
	private cache: { timestamps: number, els: Subscription[] } | null = null;
	private promise: Promise<Subscription[]> | null = null;

	constructor() {
		super();
	}

	public get(forceUpdate: boolean = false): Promise<Subscription[]> {
		if (!forceUpdate) {
			// Check si la même promesse est déjà entrain d'être demandée au serveur.
			if (this.promise !== null) {
				return this.promise;
			}

			// Check si un cache est disponible.
			let cache = this.cache;
			if (cache !== null && cache.timestamps > (new Date().getTime() - SubscriptionRepository.DELAY_OF_CACHE)) {
				return Promise.resolve(cache.els);
			}
		}

		return this.promise = this.request(this.config.pl_apiUrl + "v1/" + 'Subscriptions/', null, 'GET', false).then((returnJson: any) => {
			let subscriptions: Subscription[] = [];
			for (let r of returnJson) {
				subscriptions.push(new Subscription(r));
			}

			// On crée le cache
			this.cache = {
				timestamps: new Date().getTime(),
				els: subscriptions
			};

			// On supprimer la promesse de son cache.
			this.promise = null;

			return subscriptions;
		});
	}

	// public get_current_promo_code(onlyInUrl: boolean = false): string | null {
	// 	let promoCode: string | null = null;
	//
	// 	let urlPromoCode = Utils.getSearchParametersWithName('pc');
	// 	if (urlPromoCode !== null && typeof urlPromoCode === "string") {
	// 		promoCode = urlPromoCode;
	// 	}
	// 	if (!onlyInUrl) {
	// 		if (promoCode === null) {
	// 			promoCode = localStorage.getItem(SubscriptionRepository.LOCAL_STORAGE_PROMOCODE);
	// 		}
	// 	}
	//
	//
	// 	return promoCode;
	// }
	//
	// public set_current_promo_code(value: string): void {
	// 	localStorage.setItem(SubscriptionRepository.LOCAL_STORAGE_PROMOCODE, value);
	// }
	//
	// public delete_current_promo_code(): void {
	// 	localStorage.removeItem(SubscriptionRepository.LOCAL_STORAGE_PROMOCODE);
	// }
}