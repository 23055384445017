import Session from "./Session";
import Utils from "horizon-js-front-sdk/lib/Utils";
import moment from "moment/moment";

export default class Credit {
	public uid!: string;
	public available: number = 0;
	public creationDate!: string;
	public expirationDate: string | null = null;
	public subscriptionUid!: string;
	public used: number = 0;

	get validCredits(): number {
		let now = moment().startOf('day');
		if (moment(this.expirationDate) <= now) {
			return 0;
		}else{
			return this.available - this.used;
		}
	}

	constructor(obj: any = {}) {
		if (typeof obj === 'string') {
			obj = JSON.parse(obj);
		}
		Credit.buildFromJson(this, obj);
	}

	private static buildFromJson(instance: any, json: any) {
		if (json) {
			for (let prop in json) {
				instance[prop] = json[prop];
			}
		}
	}

	exportToJson(): any {
		let JSONExport: any = {};
		return JSONExport;
	}
}