
export type Country={ iso: string, mini: string, name: string };
export default class Countries {
    public static countries: Array<Country> = [
        {iso: "FR", mini: "fr", name: "France"},
        {iso: "DE", mini: "de", name: "Allemagne"},
        {iso: "BE", mini: "be", name: "Belgique"},
        {iso: "CA", mini: "ca", name: "Canada"},
        {iso: "CN", mini: "cn", name: "China"},
        {iso: "KR", mini: "kr", name: "Corée du sud"},
        {iso: "DK", mini: "dk", name: "Danemark"},
        {iso: "US", mini: "us", name: "États-Unis"},
        {iso: "ES", mini: "es", name: "Espagne"},
        {iso: "FI", mini: "fi", name: "Finlande"},
        {iso: "IE", mini: "ie", name: "Irland"},
        {iso: "IT", mini: "it", name: "Italie"},
        {iso: "JP", mini: "jp", name: "Japon"},
        {iso: "LU", mini: "lu", name: "Luxembourg"},
        {iso: "MG", mini: "mg", name: "Madagascar"},
        {iso: "NO", mini: "no", name: "Norvège"},
        {iso: "NL", mini: "nl", name: "Pays-Bas"},
        {iso: "PL", mini: "pl", name: "Pologne"},
        {iso: "PT", mini: "pt", name: "Portugal"},
        {iso: "GB", mini: "gb", name: "Royaume-Uni"},
        {iso: "SE", mini: "se", name: "Suède"},
        {iso: "CH", mini: "ch", name: "Suisse"},
        {iso: "TN", mini: "tn", name: "Tunisie"}
    ];

    constructor(obj: any = {}) {
        // if (typeof obj === 'string') {
        // 	obj = JSON.parse(obj);
        // }
        // Countries.buildFromJson(this, obj);
    }

    private static buildFromJson(instance: any, json: any) {
        if (json) {
            for (let prop in json) {
                if (json.hasOwnProperty(prop)) {
                    instance[prop] = json[prop];
                }
            }
        }
    }

    public static is_valid_country(ctr: string, option: { iso?: boolean, name?: boolean } = {iso: true}): boolean {
        for (let country of this.countries) {
            if (option.iso) {
                if (country.iso === ctr) {
                    return true;
                }
            } else if (option.name) {
                if (country.name === ctr) {
                    return true;
                }
            }
        }
        return false;
    }
}
