import Repository from "./Repository";
import Family from "../classes/Family";
import FamilyEvent from "../classes/FamilyEvent";
import Target from "../classes/Target";
import Payment from "../classes/Payment";

import Template from "../classes/Template";
import moment from "moment";
import User from "gaia-sdk-js/lib/src/class/Identity/User";
import GoogleTagManagerData, {GTM_Event} from "../libs/GoogleTagManager/src/class/GoogleTagManagerData";
import GtmManager from "../libs/GoogleTagManager/src/GTMManager";
import {DependencyInjectorInstance} from "horizon-js-front-sdk/lib/DependencyInjector";
import Credit from "../classes/Credit";
import Utils from "horizon-js-front-sdk/lib/Utils";

export interface FamilyModifier {
	current_family_modifier(family: Family): void;

	current_families_modifier(families: Family[]): void;

	current_family_credit(credit:number):void;
}

export class FamilyRepository extends Repository {
	static STORAGE_KEY_PAYMENT_URL = 'mpc_switchly_payment_url';
	static STORAGE_KEY_CURRENT_FAMILY = 'mpc_current_family';
	static STORAGE_KEY_FAMILIES = 'mpc_families';
	static STORAGE_INIT_PAYMENT = 'mpc_init_payment';
	public static LOCAL_STORAGE_FAMILYLINK = 'mpc_familiesLink';

	/** Source **/
	private static STORAGE_SOURCE = 'mpc_source';
	public static URL_PARAMETER_SOURCE = 'source';

	private static DELAY_OF_CACHE: number = 60 * 1000; // X * 1000 => X in seconde.
	private cache_families: { [familyUid: string]: { timestamps: number, family: Family } | null } = {};

	private gtmManager: GtmManager = DependencyInjectorInstance().getInstance(GtmManager);

	// index = familyUid
	private promises_family: Promise<Family[]> | null = null;


	private l: { id: string, view: FamilyModifier }[] = [];

	constructor() {
		super();
	}

	listen(key: string, obj: FamilyModifier): boolean {
		let isKeyAvailable: boolean = true;
		for (let i of this.l) {
			if (i.id === key) {
				isKeyAvailable = false;
				break;
			}
		}
		if (isKeyAvailable) {
			this.l.push({id: key, view: obj});
		}
		return isKeyAvailable;
	}

	unListen(key: string) {
		for (let i in this.l) {
			if (this.l[i].id === key) {
				this.l.splice(parseInt(i), 1);
			}
		}
	}

	fire_current_family_changed(value: Family) {
		for (let l of this.l) {
			l.view.current_family_modifier(value);
		}
	}

	fire_current_families_changed(value: Family[]) {
		for (let l of this.l) {
			l.view.current_families_modifier(value);
		}
	}

	fire_current_credit(value:number) {
		for (let l of this.l) {
			l.view.current_family_credit(value);
		}
	}

	public get(): Promise<Family[]> {
		// Check si la même promesse est déjà entrain d'être demandée au serveur.
		if (this.promises_family) {
			return this.promises_family;
		}

		return this.promises_family = this.request(this.config.pl_apiUrl + "v1/" + 'Families/', null, 'GET', false).then((returnJson: any) => {

			// On vire la promesse de son cache
			this.promises_family = null;

			let families: Family[] = [];
			for (let r of returnJson) {
				families.push(new Family(r));
			}
			this.set_families_ls(families);

			this.get_current(false).then((family: Family | null) => {
				if (family) {
					for (let f of families) {
						if (f.uid === family.uid) {
							this.set_current(f);
						}
					}
				} else if (families.length > 0) {
					this.set_current(families[0])
				}
			});

			this.fire_current_families_changed(families);
			return families;
		});
	}

	public getCredit(FamilyUid: string): Promise<Credit[]> {
		return this.request(this.config.pl_apiUrl + "v1/" + 'Families/' + FamilyUid + '/Credits/', null, 'GET', false).then(function (returnJson: any) {
			return returnJson.map(el => new Credit(el));
		});
	}

	public async getAvailableCredit(FamilyUid: string): Promise<number> {
		let credits = await this.getCredit(FamilyUid);
		let available =  credits.reduce((accumulator, currentValue) => accumulator + currentValue.validCredits, 0);
		this.fire_current_credit(available);
		return Promise.resolve(available);
	}

	public async get_current(verifyByApi: boolean = true): Promise<Family | null> {
		let family_ls: Family | null = this.get_family_ls();
		if (family_ls !== null) {
			this.fire_current_family_changed(family_ls);
			return Promise.resolve(family_ls);
		} else if (verifyByApi) {
			return this.get().then((families: Family[]) => {
				if (families.length > 0) {
					this.set_current(families[0]);
					return families[0];
				} else {
					// Si la personne n'a aucune famille, dans ce cas on supprime les caches potentiels.
					this.delete_current_family();
					this.delete_family_list();
					return null;
				}
			}).catch((reason)=>{
				return null;
			});
		} else {
			return Promise.resolve(null);
		}
	}

	public get_family_ls(): Family | null {
		let ls = localStorage.getItem(FamilyRepository.STORAGE_KEY_CURRENT_FAMILY);
		if (ls) {
			let parse_ls = JSON.parse(ls);
			return new Family(parse_ls);
		} else {
			return null;
		}
	}

	public set_current(family: Family): Family | null {
		localStorage.setItem(FamilyRepository.STORAGE_KEY_CURRENT_FAMILY, JSON.stringify(family.exportToJson()));
		let f = this.get_family_ls();
		if (f !== null) {
			this.fire_current_family_changed(f);
			return f;
		}
		return null;
	}

	private set_families_ls(families: Family[]): Family[] {
		let f: any[] = [];
		for (let fam of families) {
			f.push(fam.exportToJson());
		}
		localStorage.setItem(FamilyRepository.STORAGE_KEY_FAMILIES, JSON.stringify(f));

		return this.get_families_ls();
	}

	public get_families_ls(): Family[] {
		let ls = localStorage.getItem(FamilyRepository.STORAGE_KEY_FAMILIES);
		if (ls) {
			let parse_ls = JSON.parse(ls);

			let fls: Array<Family> = [];
			for (let l of parse_ls) {
				fls.push(new Family(l));
			}
			return fls;
		} else {
			return [];
		}
	}

	public getOne(uid: string): Promise<Family> {
		return this.request(this.config.pl_apiUrl + "v1/" + 'Families/' + uid + '/', null, 'GET', false).then(function (returnJson: any) {
			return new Family(returnJson);
		});
	}

	public set(family: Family): Promise<Family> {
		if (!family.uid) {
			return this.request(this.config.pl_apiUrl + "v1/" + 'Families/', family.exportToJson(), 'POST', false).then((returnJson: any) => {
				// @ts-ignore
				window.dataLayer.push({
					'event': 'create_family',
					'family_name': family.name,
					'subscription_uid': family.subscriptionUid,
					'source': family.source,
					'owner_uid': family.ownerUid
				});
				return new Family(returnJson);
			});
		} else {
			return this.request(this.config.pl_apiUrl + "v1/" + 'Families/' + family.uid + '/', family.exportToJson(), 'PUT', false).then((returnJson: any) => {
				let family_from_bdd = new Family(returnJson)
				this.set_current(family_from_bdd);

				// @ts-ignore
				window.dataLayer.push({
					'event': 'update_family',
					'old_family_name': family.name,
					'family_name': family_from_bdd.name,
					'subscription_uid': family.subscriptionUid,
					'source': family.source,
					'owner_uid': family.ownerUid
				});

				return family_from_bdd;
			});
		}
	}

	public set_discount_code(family: Family): Promise<Family> {
		if (!family.uid) {
			return Promise.reject();
		} else {
			return this.request(this.config.pl_apiUrl + "v1/" + 'Families/' + family.uid + '/SetDiscountCode', family.exportToJson(), 'POST', false).then((returnJson: any) => {
				let family_from_bdd = new Family(returnJson)
				this.set_current(family_from_bdd);

				// @ts-ignore
				window.dataLayer.push({
					'event': 'update_family',
					'old_family_name': family.name,
					'family_name': family_from_bdd.name,
					'subscription_uid': family.subscriptionUid,
					'source': family.source,
					'owner_uid': family.ownerUid
				});

				return family_from_bdd;
			});
		}
	}

	public get_events(familyUid: string, periodUid: string): Promise<FamilyEvent[]> {
		return this.request(this.config.pl_apiUrl + "v1/" + 'Families/' + familyUid + '/Events/', null, 'GET', false).then(function (returnJson: any) {
			let familyEvents: FamilyEvent[] = [];
			for (let e of returnJson) {
				familyEvents.push(new FamilyEvent(e));
			}
			familyEvents.sort((a: FamilyEvent, b: FamilyEvent) => {
				return compare(a, b);
			});
			return familyEvents;
		});

		function compare(a: FamilyEvent, b: FamilyEvent) {
			if (moment(a.eventDate).isBefore(b.eventDate)) {
				return 1;
			}
			if (moment(a.eventDate).isAfter(b.eventDate)) {
				return -1;
			}
			return 0;
		}
	}

	public get_events_preview(familyUid: string, event: FamilyEvent): Promise<any> {
		return this.request(this.config.pl_apiUrl + "v1/" + 'Families/' + familyUid + '/Events/Preview', event.exportToJson(true), 'POST', false, {
			dataType: null
		}).then(function (returnJson: any) {
			return returnJson;
		});
	}

	public get_one_event(familyUid: string | null, eventUid: string): Promise<FamilyEvent> {
		return this.request(this.config.pl_apiUrl + "v1/" + (familyUid ? ('Families/' + familyUid + '/') : '') + 'Events/' + eventUid + '/', null, 'GET').then(function (returnJson: any) {
			return new FamilyEvent(returnJson);
		});
	}

	public set_events(familyUid: string, event: FamilyEvent): Promise<FamilyEvent> {
		if (event.uid) {
			return this.request(this.config.pl_apiUrl + "v1/" + 'Families/' + familyUid + '/Events/' + event.uid + '/' + (!event.data.uploadBase64 ? '?keepUpload=true' : ''), event.exportToJson(), 'PUT', false).then(function (returnJson: any) {
				return new FamilyEvent(returnJson);
			});
		} else {
			return this.request(this.config.pl_apiUrl + "v1/" + 'Families/' + familyUid + '/Events/', event.exportToJson(),
				'POST',
				false,
				{onprogress: {eventName: "progress-upload"}})
				.then(function (returnJson: any) {
						return new FamilyEvent(returnJson);
					}
				)
		}
	}

	public delete_event(familyUid: string, eventUid: string): Promise<FamilyEvent> {
		return this.request(this.config.pl_apiUrl + "v1/" + 'Families/' + familyUid + '/Events/' + eventUid + '/', null, 'DELETE', false).then(function (returnJson: any) {
			return new FamilyEvent(returnJson);
		});
	}

	public get_one_target(familyUid: string, targetUid: string): Promise<Target> {
		return this.request(this.config.pl_apiUrl + "v1/" + 'Families/' + familyUid + '/Targets/' + targetUid + '/', null, 'GET').then(function (returnJson: any) {
			return new Target(returnJson);
		});
	}

	public set_payments(familyUid: string, params?: {
		returnUri?: string,
		manageError?: boolean,
		credit?: number
	}): Promise<Payment> {
		params = initParam(params);
		return this.request(this.config.pl_apiUrl + "v1/" + 'Families/' + familyUid + '/Payments/', {
			returnUri: params.returnUri,
			creditCount: params.credit
		}, 'POST').then((returnJson: any) => {
			console.log("returnJson",returnJson)
			let payment = new Payment(returnJson);
			if (payment.redirectUrl) {
				this.sendPaymentToGTM(null, payment.amount);
				localStorage.setItem(FamilyRepository.STORAGE_KEY_PAYMENT_URL, JSON.stringify(payment));
			}
			return payment;
		}).catch((xhr: any) => {
			console.log("xhr",xhr)
			if (xhr && xhr.responseJSON) {
				if (xhr.responseJSON.error === "payment_processor_unavailable") {
					if (params && params.manageError) {
						alert("La plateforme de paiement est inaccessible, merci de réessayer dans quelques minutes");
					}
					// @ts-ignore
					$('body').toast({
						class: 'error',
						title: `Plateforme de paiement inaccessible.`,
						message: `Si le problème persiste d'ici quelques minutes n'hésitez pas à nous contacter.`,
						showIcon: 'times',
						showProgress: 'bottom',
					});
					return Promise.reject(xhr.responseJSON.error);
				} else if (xhr.responseJSON.error === "payment_already_setup") {
					return Promise.reject(xhr.responseJSON.error);
				} else{
					return Promise.reject(xhr.responseJSON.error);
				}
			} else {
				return Promise.reject(xhr);
			}
		});

		function initParam(params?: { returnUri?: string, manageError?: boolean, credit?: number }): {
			returnUri: string,
			manageError: boolean,
			credit: number
		} {
			return {
				returnUri: params && params.returnUri ? params.returnUri : window.location.href,
				manageError: params && params.manageError !== undefined ? params.manageError : false,
				credit: params && params.credit !== undefined ? params.credit : 1
			};
		}
	}

	public get_generateBooks(familyUid: string): Promise<any> {
		return this.request(this.config.pl_apiUrl + "v1/" + 'Cron/GenerateBooks', null, 'GET').then(function (returnJson: any) {
			return returnJson;
		});
	}

	public get_templates(subscriptionUid: string): Promise<any> {
		return this.request(this.config.pl_apiUrl + "v1/" + 'Subscriptions/' + subscriptionUid + '/Templates/', null, 'GET', false).then(function (returnJson: any) {
			let templates: Template[] = [];
			for (let t of returnJson) {
				templates.push(new Template(t));
			}
			return templates;
		});
	}

	public get_invit_link(): string[] {
		let fl = localStorage.getItem(FamilyRepository.LOCAL_STORAGE_FAMILYLINK);
		if (fl) {
			return JSON.parse(fl);
		} else {
			return [];
		}
	}

	public set_invit_link(familyUid: string): void {
		let fl: string[] = this.get_invit_link();
		let isAlreadyExist: boolean = false;
		for (let f of fl) {
			if (f === familyUid) {
				isAlreadyExist = true;
				break;
			}
		}
		if (!isAlreadyExist) {
			fl.push(familyUid);
		}

		localStorage.setItem(FamilyRepository.LOCAL_STORAGE_FAMILYLINK, JSON.stringify(fl));
	}

	public delete_invit_link() {
		localStorage.removeItem(FamilyRepository.LOCAL_STORAGE_FAMILYLINK);
	}

	public delete_family_list() {
		localStorage.removeItem(FamilyRepository.STORAGE_KEY_FAMILIES);
	}

	public delete_current_family() {
		localStorage.removeItem(FamilyRepository.STORAGE_KEY_CURRENT_FAMILY);
	}

	public remove_family_invitation_link() {
		localStorage.removeItem(FamilyRepository.LOCAL_STORAGE_FAMILYLINK);
	}

	static ask_payment(family: Family): boolean {
		if (family.uid) {
			let last_ask = get_last_ask();
			if (last_ask) {
				if (last_ask + (24 * 60 * 60 * 1000) < new Date().getTime()) {
					set_last_ask();
					return true
				}
			} else {
				set_last_ask();
				return true;
			}
		}

		return false;

		function get_last_ask(): number | null {
			let a = localStorage.getItem("mpc_last_ask_payment");
			if (a !== null) {
				return parseInt(a + "");
			}
			return a;
		}

		function set_last_ask() {
			let a = localStorage.setItem("mpc_last_ask_payment", JSON.stringify(new Date().getTime()));
		}
	}

	private sendPaymentToGTM(user: User | null = null, amount: number = 590) {
		try {
			amount = amount / 100;
			/** Envoie à GTM **/
			let GTMData: GoogleTagManagerData = new GoogleTagManagerData();
			GTMData.ecommerce = {
				value: amount,//Prix total
				currency: 'EUR',
				items: [{item_id: "mpc", price: amount, item_name: "Journal", quantity: 1}],
			}
			if (user && user.email) {
				GTMData.profile = {email: user.email};
				if (user.first_name)
					GTMData.profile.firstname = user.first_name;

				if (user.last_name)
					GTMData.profile.lastname = user.last_name;

			}
			this.gtmManager.sendEvent(GTMData, GTM_Event.BEGIN_CHECKOUT);
		} catch (e) {
		}
	}



	public get_current_source(onlyInUrl: boolean = false): string | null {
		let promoCode: string | null = null;

		let urlSource = Utils.getSearchParametersWithName(FamilyRepository.URL_PARAMETER_SOURCE);
		if (urlSource !== null && typeof urlSource === "string") {
			promoCode = urlSource;
		}
		if (!onlyInUrl) {
			if (promoCode === null) {
				promoCode = localStorage.getItem(FamilyRepository.STORAGE_SOURCE);
			}
		}


		return promoCode;
	}

	public set_current_source(value: string): void {
		localStorage.setItem(FamilyRepository.STORAGE_SOURCE, value);
	}

	public delete_current_source(): void {
		localStorage.removeItem(FamilyRepository.STORAGE_SOURCE);
	}
}