export default class Session {
	static getOauthToken(): string | null {
		let oauthToken = window.localStorage.getItem('oauth-token');
		if (oauthToken !== null) {
			return oauthToken;
		} else {
			return null;
		}
	}

	static clean_mpc_localStorage() {
		localStorage.removeItem("mpc_switchly_payment_url");
		localStorage.removeItem("mpc_current_family");
		localStorage.removeItem("mpc_families");
		localStorage.removeItem("mpc_profile");
	}

	static clean_mpc_connexion_localStorage() {
		localStorage.removeItem("oauth-token");
		localStorage.removeItem("oauth-expires_at");
		localStorage.removeItem("logged_at");
	}
}